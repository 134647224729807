import React from 'react'
import '../AboutUs/AboutUs.css'
import Header from '../Header/Header'
import Footer from './Footer'
import privacyImage from './pexels-markus-winkler-12188471.jpg'

const PrivacyPolicy = () => {
  return (
    <>
        <Header />
        <div className="abtus-wrapper" style={{marginTop:"4.5rem"}}>
            <div className="abtus-container">
                <h2>Privacy Policy</h2>
                <div className="abtus-item">
                    
                    <div className="abtus-left">
                    <p>Welcome to OJISERVICES.IN. We are a real estate RENTAL service providing agency with access
                    to some relevant services which is necessary for rental process of any property. Our mobile application
                    is going to be lunch very soon. All the products and services available through our website is owned and
                    operated by Oji Consultancy Private Limited, a private limited company incorporated under the laws of
                    India.</p>

                    
                    </div>
                    <div className="abtus-right">
                        <img src={privacyImage} alt=""  />
                    </div>
                </div>
                
                <div className="abtus-items">
                <p>Our Privacy Policy is incorporated as part of the ojiservices.in Terms of Use. Please read this
                        Privacy Policy and our Terms of Use carefully before using our Services. By using ojiservices.in’s
                        Services, you agree to the provisions of this Privacy Policy. If you do not want to be bound by our Privacy
                        Policy, please do not use our Services.</p>
                    <h3>
                    Why We Collect Information from You:-</h3>
                    <p>
                        <p>ojiservices.in is a real estate rental information service. To provide our Services, we use the
                            information we collect to:</p>
                        <ul>
                            <li>Help you find the most relevant information for your situation by customizing our Services to optimize
                            your experience;</li>
                            <li>Keep you connected with news and information through ojiservices.in across the internet and
                                update you with all types of residential &amp; commercial properties which is to be used only for rental
                                purpose; and put you in touch with the right people to rent.</li>
                            <li>And to optimize the information we share with those parties to initiate a productive and efficient
                                relationship.</li>
                        </ul>
                    </p>
                    <h3>
                    To Whom Does This Policy Apply:-</h3>
                    <p>
                    Some parts of this Policy are directed at specific types of people. This Policy applies to everyone who
                    uses our Services, but sometimes we direct parts of it toward those particular groups of users.
                    </p>
                    <h3>
                    What Information Do We Collect, and Where Do We Get It:-</h3>
                    <p>
                    We Collect Some Information from All Visitors to Our Website: Like many online services, and third-
                    party analytics services to collect and analyze information about all users of our Services This includes
                    things like User search preferences &amp; interaction.
                    </p>
                    <p>
                    Our servers never record any information automatically like &quot;Log Data&quot; which is created by your use of
                    the Services which may include information such as your IP address, browser type, operating system,
                    the referring web page, pages visited, location, your mobile number, your mobile carrier, your email
                    address, device, search terms, and cookie information.
                    </p>
                    <p>
                    We receive Log Data when you interact with our Services, for example, when you visit our websites, sign
                    into our Services, or interact with our email notifications. We use Log Data to provide our Services and
                    to measure, customize, and improve them, as well as collect and use them in the aggregate.
                    </p>
                    <p>
                    This Policy does not apply to, nor do we take any responsibility for any information that is collected by
                    any third party either using the Website or through any links on the Website or through any of the
                    advertisements.</p>
                    <p>
                    We collect some personal information like name, contact details, mobile number, email address, profile
                    images/photos/pictures which you have provided us during the account creation / registration process
                    or while interacting with any of our services to ensure a smoother user experience and to tailor our
                    services to your preferences and interests.</p>
                    <p>
                    We never collect any financial data from any subscriber like mode of payment, IPI ID’s, any type of credit
                    card or debit card information, CVV or OTP. We use only mobile no, email addresses and billing address
                    from subscribers, so that we can handle billing.</p>
                    <p>
                    Location Data: If you provide location information (like a ZIP code, street address, or even just a city)
                    during the registration process or at any other time via your account settings, we will store that
                    information and associate it with your account. We may use these information about your location to
                    provide localized features, to analyze our Services with the help of third-party analytics services to
                    improve and customize our Services.</p>
                    <h3>How We Share Your Information:-</h3>
                    <p>
                    As you use, access and navigate our Website, we collect various information, which may either be
                    personally identifiable information or information that is not personally identifiable. This information is
                    classified as:</p>
                    <ul>
                        <li>“Personal Information” which means personally identifiable information like your name, your address,
                            your age, your email address and your phone number.</li>
                        <li>“Sensitive Personal Information” which means partial financial information and your passwords
                        information etc.</li>
                        <li>“Other Information” which means all other information other than “Personal Information” or
                        “Sensitive Personal Information.”</li>
                        <li>
                        Any personal information will be used to allow you to login to your account or to resolve specific
                        service issues, inform you of our new services or features and to communicate with you in relation to
                        your use of the Website.
                        </li>
                    </ul>
                    <h3>Any other information we collect will be used for-</h3>
                    <ul>
                    <li>our business purposes and this may include the viewing or advertising of residential or commercial
                    rental properties or to use associated services</li>
                    <li>analytical purposes, data usage</li>
                    <li>improving the Website, or your user experience.</li>
                    </ul>
                    <p>
                    We will share all information we collect from you, including the personal information with any third
                    party if we in good faith believe it is required for the purposes of our compliance with any applicable
                    law, regulation, legal process or enforceable governmental request/action or any manner required for
                    the purposes of protecting our rights or interests.
                    </p>
                    <h3>General Matters:-</h3>
                    <p>The Accuracy and Confidentiality of Your Account Information Is Your Responsibility: You are responsible
                    for maintaining the secrecy and accuracy of your password, email address, and other account
                    information at all times. We recommend a strong password that you do not use with other services. We
                    are not responsible for personal data transmitted to a third party as a result of an incorrect email
                    address.</p>
                    <h3>Third-Party Websites: </h3>
                    <p>Our Website may contain links to other websites. When you click on one of these links, you are
                    navigating to another website. We do not accept liability for misuse of any information by any website
                    controller to which we may link. We also do not have any responsibility of liability for the content on
                    websites that use the ojiservices.in search functionality. We encourage you to read the privacy
                    statements of these websites, which may differ from ours.</p>
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default PrivacyPolicy