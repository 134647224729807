import React from 'react'
import './AboutUs.css'
import aboutimg from "./aboutus.jpeg";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';

const Aboutus = () => {
  return (
    <>
    <Header />
        <div className="abtus-wrapper" style={{marginTop:"4.5rem"}}>
            <div className="abtus-container">
                <h2> About Us</h2>
                <div className="abtus-item">
                    
                    <div className="abtus-left">
                    <p>OJISERVICES.IN founded in 2022, is an attempt to facilitate every kind
                        of real estate projects. We are Odisha’s most Innovative real estate advertising
                        platform for all Houseowners, Landlords and Tenants. Customer satisfaction in
                        our prime goal. Presently we are dealing with lease &amp; rent of residential and
                        commercial properties in BHUBANESWAR, Odisha Only</p>

    
                    </div>
                    <div className="abtus-right">
                        <img src={aboutimg} alt=""  />
                    </div>
                </div>
                
                <div className="abtus-items">
                    <h3>
                    Our Vision
                    </h3>
                    <p>
                    Changing the way of your search of property
                    </p>
                    <h3>Our Mission</h3>
                    <p> To be the first choice of our consumers and partners in discovering, renting,
                    buying, selling, financing a home, and digitally enabling them throughout their
                    journey. We do that with data, design, technology, and above all, the passion of
                    our people while delivering value to our shareholders.</p>
                    <h3>Our Goal</h3>
                    <p>In this policy to be completely transparent about the data we collect, why we collect it, and how we use it, in order to make finding your new home completely in a different way of approach with pleasant and fun experience!</p>

                    <p> Email: <a href="mailto:info@ojiservices.in">info@ojiservices.in</a></p>
                    <p>For feedback, please reach us <Link to="/costumer_review" target="_blnk">Here</Link></p>
                </div>
            </div>
        </div>
    <Footer />
    </>
  )
}

export default Aboutus