import React from 'react'
import Header from '../Header/Header'
import Footer from './Footer';
import vdo3 from '../home/20230714_1529181.mp4'
import { useRef,useEffect } from 'react';

const How = () => {
    const videoEl = useRef(null);

    const attemptPlay = () => {
        videoEl &&
          videoEl.current &&
          videoEl.current.play().catch(error => {
            console.error("Error attempting to play", error);   
          });
      };

    useEffect(() => {
        attemptPlay();
        }, []);


  return (
   
    <>
        <Header />
        <div style={{width:"100%",backgroundColor:"white",padding:"3%"}}>
        <div className='vdo3' style={{marginRight:"auto",marginLeft:"auto",marginTop:"4.5rem"}}>
         <video
          style={{ maxWidth: "100%", width: "100%" ,boxShadow: "2px 2px 25px -7px #4c4c4c" ,marginTop:"-2rem",marginLeft:"auto",marginRight:"auto",marginBottom:"2rem"}} 
          playsInline
          loop
          muted
          alt="All the devices"
          src={vdo3}
          ref={videoEl}
        />
        </div>
        </div>
        <Footer />
    </>
  )
}

export default How