import React from 'react'
import './Options.css'
import Appartment from './home Images/Appartment.jpg'
import Duplex from './home Images/duplex.jpg'
import Hostel from './home Images/Hostel.jpg'
import Land from './home Images/pexels-syed-qaarif-andrabi-9420758.jpg'

import { Link } from 'react-router-dom'

const Options = () => {
   
  return (
    <>
        <section className='opn' style={{marginTop:"3rem"}}>
            <header>
                <h2>Check Out All Type Of Properties Available At Bhubaneswar For You</h2>
                <h6>You Can Find Your Dream House Directly By Contacting Us</h6>
            </header>
            <section className='opn-cards'>
                <div className='opn-box'>
                    <h4>Appartment</h4>
                    <img src={Appartment} alt="" />
                    <div className="opn-box-explain">
                        <p><span>Options :</span> 1BHK/ 2BHK/ 3BHK/ 4BHK</p>
                        <p><span>Services :</span> Rent</p>
                        <p><span>Location :</span> Bhubaneswar</p>
                    
                    </div>
                    <Link to="./apartments" target='_top'> <button>Explore More</button></Link>
                </div>
                <div className='opn-box'>
                    <h4>Duplex/Houses</h4>
                    <img src={Duplex} alt="" />
                    <div className="opn-box-explain">
                        <p><span>Options :</span> Nominal/Delux</p>
                        <p><span>Services :</span> Rent</p>
                        <p><span>Location :</span> Bhubaneswar</p>
                        
                    </div>
                    <Link to="duplex_houses" target='_top'> <button>Explore More</button></Link>
                </div>
                <div className='opn-box'>
                    <h4>PG/Hostel</h4>
                    <img src={Hostel} alt="" />
                    <div className="opn-box-explain">
                        <p><span>Options :</span> Nominal/Delux</p>
                        <p><span>Services :</span> Rent</p>
                        <p><span>Location :</span> Bhubaneswar</p>
                       
                    </div>
                    <Link to="./pg_hostels" target='_top'> <button>Explore More</button></Link>
                </div>
                <div className='opn-box'>
                    <h4>Office/Studio</h4>
                    <img src={Land} alt="" />
                    <div className="opn-box-explain">
                        <p><span>Options :</span> Single/Sharing</p>
                        <p><span>Services :</span> Rent</p>
                        <p><span>Location :</span> Bhubaneswar</p>
                        
                    </div>
                   <Link to="./office_studio" target='_top'> <button>Explore More</button></Link>
                </div>
            </section>
        </section>
    </>
  )
}

export default Options