import React from 'react';
import CustomSearch from './CustomSearch';
import './Home.css';
import Options from './Options';
import WhyChoose from './WhyChoose';
import Featured from './Featured';
import Review1 from './Review/Review1';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import vdo from "./20230625_1539141.mp4";
import vdo1 from "./20230625_1558521.mp4";
import vdo2 from "./20230625_2213411.mp4"
import vdo3 from "./20230714_1529181.mp4"
import iso from "./iso logo.png"
import { useRef , useEffect} from 'react';
import ServicesCard from '../Footer/ServicesCard';




const Home = () => {
  const videoEl = useRef(null);
  const videoEl2 = useRef(null);
  const videoEl3 = useRef(null);
  const videoEl4 = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };
  const attemptPlay2 = () => {
    videoEl2 &&
      videoEl2.current &&
      videoEl2.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };
  const attemptPlay3 = () => {
    videoEl3 &&
      videoEl3.current &&
      videoEl3.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };
  const attemptPlay4 = () => {
    videoEl4 &&
      videoEl4.current &&
      videoEl4.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);
  useEffect(() => {
    attemptPlay2();
  }, []);
  useEffect(() => {
    attemptPlay3();
  }, []);
  useEffect(() => {
    attemptPlay4();
  }, []);
 
 
  return (
    <>
      <Header /> 
      <section className='section-one' style={{display:"flex",alignItems:"center"}}>
      <video
          style={{boxShadow: "2px 2px 25px -7px #4c4c4c"}} 
          playsInline
          loop
          muted
          alt="All the devices"
          src="https://dv4rpk71v7io2.cloudfront.net/20230625_1558521.mp4"
          ref={videoEl}
          className='video1'
        />
        <div>
          <img src={iso} alt="ISO"  />
        </div>
      </section>
      
      <section className='section-two'>
          <CustomSearch />
      </section>
      <div className='vdo3' style={{marginRight:"auto",marginLeft:"auto"}}>
      <video
          style={{ maxWidth: "100%", width: "100%" ,boxShadow: "2px 2px 25px -7px #4c4c4c",marginTop:"3rem",}} 
          playsInline
          loop
          muted
          alt="All the devices"
          src="https://dv4rpk71v7io2.cloudfront.net/20230714_1529181.mp4"
          ref={videoEl4}
        />
        </div>
      <div className='home-service'>
        <h2>Services Offered By Us</h2>
        <h3>Contact us for any Service</h3>
        <ServicesCard />
      </div>
      <Options />
      <WhyChoose />
      <div className="moto">
      <video
          style={{boxShadow: "2px 2px 25px -7px #4c4c4c"}} 
          playsInline
          loop
          muted
          alt="All the devices"
          src="https://dv4rpk71v7io2.cloudfront.net/20230625_1539141.mp4"
          ref={videoEl3}
          className='vdo1'
        />
        <video
          style={{boxShadow: "2px 2px 25px -7px #4c4c4c"}} 
          playsInline
          loop
          muted
          alt="All the devices"
          src="https://dv4rpk71v7io2.cloudfront.net/20230625_2213411.mp4"
          ref={videoEl2}
          className='vdo2'
        />
      
      </div>
      <Review1 />
      <Footer />
    </>
  )
}

export default Home;