import React from 'react'
import RatingStar from './RatingStar'
import './Review1.css';
import r1 from './Review Images/r1.jpeg'
import r2 from './Review Images/r2.jpeg'
import r3 from './Review Images/r3.jpeg'
import r4 from './Review Images/r4.jpeg'
import r5 from './Review Images/r5.jpeg'
import r6 from './Review Images/r6.jpeg'

export const Review1 = () => {
 
  return (
    <div className='r-body'>
    <h2 className="r-heading">Reviews of Our Customer</h2>
    <section className="review-body">
    <div className="r-container">
      <input type="radio" name="dot" id="r-one" />
      <input type="radio" name="dot" id="r-two" />
      <div className="review-card">
        <div className="r-card-box r-box1 ">
          <div className="r-card">
            <div className="r-content">
              <div className="r-img">
                <img src="https://dv4rpk71v7io2.cloudfront.net/r1.jpeg" alt="1" />
              </div>
              <div className="r-details">
                    <h4 className>Mr.Saroj Kumar</h4>
                    <p>Ghar Mast Hai</p>
                    <RatingStar />
              </div>
              
            </div>
          </div>
          <div className="r-card">
            <div className="r-content">
              <div className="r-img">
                <img src="https://dv4rpk71v7io2.cloudfront.net/r2.jpeg" alt="2" />
              </div>
              <div className="r-details">
                    <h4>Miss.Smriti Mishra</h4>
                    <p>Osm services and price</p>
                    <RatingStar />
              </div>
              
            </div>
          </div>
          <div className="r-card">
            <div className="r-content">
              <div className="r-img">
                <img src="https://dv4rpk71v7io2.cloudfront.net/r3.jpeg" alt="3" />
              </div>
              <div className="r-details">
                    <h4>Miss.Prakruti Singh</h4>
                    <p>Good service</p>
                    <RatingStar />
              </div>
              
            </div>
          </div>
        </div>
        <div className="r-card-box r-box2 ">
          <div className="r-card">
            <div className="r-content">
              <div className="r-img">
                <img src="https://dv4rpk71v7io2.cloudfront.net/r4.jpeg" alt="4" />
              </div>
              <div className="r-details">
                    <h4>Mr.Srikant Roy</h4>
                    <p>Home sweet home </p>
                    <RatingStar />
              </div>
              
            </div>
          </div>
          <div className="r-card">
            <div className="r-content">
              <div className="r-img">
                <img src="https://dv4rpk71v7io2.cloudfront.net/r5.jpeg" alt="5" />
              </div>
              <div className="r-details">
                    <h4>Mr.Joy Sinha</h4>
                    <p>Found my dream home </p>
                    <RatingStar />
              </div>
              
            </div>
          </div>
          <div className="r-card">
            <div className="r-content">
              <div className="r-img">
                <img src="https://dv4rpk71v7io2.cloudfront.net/r6.jpeg" alt="6" />
              </div>
              <div className="r-details">
              <h4>Miss.Aparna Swain</h4>
                    <p>Very good service </p>
                    <RatingStar />
              </div>
              
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </section>
  </div>
  )
}
export default Review1