import React from 'react'
import '../AboutUs/AboutUs.css'
import refundimg from "./pexels-andrea-piacquadio-3760067.jpg";
import Header from '../Header/Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';


const RefundPolicy = () => {
  return (
    <>
    <Header />
        <div className="abtus-wrapper" style={{marginTop:"4.5rem"}}>
            <div className="abtus-container">
                <h2>Refund Policy</h2>
                <div className="abtus-item">
                    
                    <div className="abtus-left">
                        <h3>Authorization:</h3>
                    <p>
                        By initiating this transaction on the Platform, you authorize us to hold, receive and disburse
                        funds in accordance with your payment instructions to the designated bank account of the
                        Beneficiary. Your authorization permits us to charge the mentioned amount to your Bank
                        Account, Credit Card, UPI Account, Debit Card, Payment Wallets or any other Payment
                        Instruments as may be enabled on our platform from time to time for remitting the amount to
                        the designated Bank account of the Beneficiary. </p>

    
                    </div>
                    <div className="abtus-right">
                        <img src={refundimg} alt=""  />
                    </div>
                </div>
                
                <div className="abtus-items">
                <p>When you instruct us to pay the Beneficiary,
                        you authorize and order us to commit your payment (less any applicable fees, Service Charges,
                        or other amounts that we may collect under this Agreement) to such person. In case, the
                        transaction is not processed due to incomplete details being provided by you then we shall
                        refund the amount to you after deducting the applicable Service Charges, Transaction fees, and
                        penalty amount.</p>
                <h3>Transaction terms:</h3>
                <p>You would be responsible for entering the correct bank accounts details of the Beneficiary at
                the time of making the transaction and in no circumstances, OJISERVICES.IN shall be
                held responsible for any wrongful credit due to incorrect bank details. The user would be
                responsible for any fraudulent transactions that happened through his bank account, credit
                card account, etc. by any third party due to his negligence and/or providing your bank or card
                details to any third party. In no circumstances, OJISERVICES.IN shall be held responsible
                for any such fraudulent transactions and no refund shall be made by OJISERVICES.IN
                once the money is successfully credited to the designated bank account of the Beneficiary. We
                may delay, suspend or reject a payment(s) transaction for any reason, including without
                limitation if we suspect the transaction subjects us to financial or security risk or is
                unauthorized, fraudulent, suspicious, unlawful, in violation of the terms of this Agreement,
                subject to dispute or otherwise unusual.</p>
                <h3>Liability:</h3>
                <p>You agree not to hold OJISERVICES.IN responsible and/or liable for any issue or claim
                arising out of any dispute whatsoever between you and/or the recipient and/or any financial
                institution. You agree to hold harmless OJISERVICES.IN for any transaction done by you
                with incorrect details etc. which gets credited in any wrong account. You agree to fully
                indemnify and hold harmless OJISERVICES.IN, its affiliates, and employees against any claim or
                action by any person or regulatory authorities.</p>

                <h3>Suspicious / Fraudulent transactions:</h3>
                <p>The User is supposed to immediately notify us in case any suspicious/fraudulent transaction is
                observed by him. It is the User’s responsibility to keep his financial details strictly confidential
                and not share them with any other person. As soon as any suspicious / fraudulent transactions
                get reported, we shall take the necessary measures and try to stop the same. However, we shall
                under no circumstances be liable for any refund, etc. to any person / Authority for such
                transactions if the money is credited to the designated bank account of the Beneficiary as
                provided by the User.</p>

                <h3>Refunds:</h3>
                <p>In case, OJISERVICES.IN is not able to transfer the rent amount to the designated bank
                account of the Beneficiary due to any technical issues, then we shall refund the amount in the
                same account from which the remittance was initiated. OJISERVICES.IN shall under no
                circumstances be liable to repay / refund the amount to the User once the amount has
                successfully been credited in the designated bank account of the Beneficiary.</p>
                <p>Chargeback: The amount of a transaction may be charged back or reversed (a &quot;Chargeback&quot;)
                from the Beneficiary if the transaction is either: (a) is disputed by the Remitter, (b) is reversed
                for any reason, (c) was not authorized or we have reason to believe that the transaction was
                not authorized, or (d) is allegedly unlawful, suspicious, or in violation of the terms of this
                Agreement. In such cases, the Recipient of the amount will immediately pay us the amount of
                any such Chargeback and any associated fees, fines, or penalties assessed by our associated
                Payment Network Gateways / Financial Institutions. The User/ Beneficiary shall assist us when
                requested, at their own expense, to investigate any of the disputed transactions processed
                through our Platform. For any such Chargeback associated with Cards, we will assist the
                User/Beneficiary to contest the Chargeback with the Network or issuing banks in case the
                Beneficiary chooses to contest any of such Chargeback. In case of the Beneficiary’s failure to
                timely assist us in investigating a transaction, including without limitation providing necessary
                documentation within 3 business days of our request, may result in an irreversible Chargeback.
                Any Chargeback shall be the sole responsibility of the Beneficiary and we shall not be liable in
                any manner whatsoever for the same.</p>
                </div>
            </div>
        </div>
    <Footer />
    </>
  )
}

export default RefundPolicy