import React, { useState, useEffect } from "react";

// import { addCart } from "../redux/action";
import { useNavigate, useParams } from "react-router-dom";
import './details.models.css';
import SimpleImageSlider from "react-simple-image-slider";
import useResizeObserver from "use-resize-observer";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import soldOut from "./soldout.png"



export default function Product() {

  const { id } = useParams();
  const [item, setItem] = useState([]);
  const [loading, setLoading] = useState(false);
  

  useEffect(() => {
    const getItem = async () => {
      setLoading(true);
      const response = await fetch(`https://ojiservices.online/api/listing/${id}`);
      setItem(await response.json());
      setLoading(false);
    };
    getItem();
  }, []);

  
  

  const Loading = () => {
    return (
      <>
      <Header />
        <p style={{background:"white", height:"40rem"}}>Please wait !! the page is loading...</p>
      <Footer />
      </>
    );
  };
  
 

  const ShowProduct = () => {
    const images = [
     `${item.picture1}`,
     `${item.picture2}` === "" ? `${item.picture1}` : `${item.picture2}`,
     `${item.picture3}` === "" ? `${item.picture1}` : `${item.picture3}`,
     `${item.picture4}` === "" ? `${item.picture1}` : `${item.picture4}`,
     `${item.picture5}` === "" ? `${item.picture1}` : `${item.picture5}`, 
    ];
    
    const realimages = images.filter((x) => !Object.values(x).includes(""));
    const { ref, width = 1, height = 1 } = useResizeObserver();
    const navigate = useNavigate();

    const scrollToTop = () => {
      window.scrollTo(0, 0)
  }

    return (
      <>
        <div style={{marginTop:"4.5rem"}}>
          <section className="product">
          <div className="product__photo">
            <div className="photo-container" ref={ref}>
            <SimpleImageSlider 
                width={width}
                height={height}
                style={{overflow:"hidden",marginBottom:"2rem",marginLeft:"auto",marginRight:"auto"}}
                images={images}
                showBullets={true}
                showNavs={true}
              />
            </div>
          </div>
        <div className="product__info" style={{marginTop:"-2rem"}}>
          <div className="title">
            <h1>{item.title}</h1>
            <h3>Available For : {item.category}</h3>
            <p style={{color:"orangered",fontSize:"10px",textAlign:"start",marginTop:".5rem"}}>*** Note this Property Id as it is requied at payment time ***</p>
            <h5 style={{marginTop:".5rem",textAlign:"start"}}>Property Id:{" "}{item.id}</h5>
            <span> {item.description}</span>
            
          </div>
          <div className="price">
            Price : Rs. <span>{item.price}</span> per{" "}{item.rental_frequency}<br />
            Property Size :<span>{item.property_size}</span> Square Ft.
          </div>
          <div className="description">
            <h3 style={{marginTop:"-1rem"}}>DETAILS</h3>
            <ul>
              <li>Area : {item.area}</li>
              <li>Place : {item.place}</li>
              <li>Rooms : {item.rooms}</li>
              <li>AMENITIES :</li>
            </ul>
            <div className="prd-label">
              <div className="prd-label-div">
                <input type="checkbox" id="furnishec" name="furnished" checked={item.furnished} readOnly/>
                <label htmlFor="furnished"> Furnished</label><br />
              </div>
              <div className="prd-label-div">
              <input type="checkbox" id="only-girls" name="only-girls" checked={item.only_girls} readOnly/>
              <label htmlFor="only-girls"> Only For Girls</label><br />
              </div>
              <div className="prd-label-div">
              <input type="checkbox" id="bachelor" name="bachelor" checked={item.bachelor} readOnly/>
              <label htmlFor="bachelor"> Bachelor</label><br />
              </div>
              <div className="prd-label-div">
              <input type="checkbox" id="elevator" name="elevator" checked={item.elevator} readOnly/>
              <label htmlFor="elevator"> Elevator/Lift</label><br />
              </div>
              <div className="prd-label-div">
              <input type="checkbox" id="parking" name="parking" checked={item.parking} readOnly/>
              <label htmlFor="parking"> Parking</label><br/>
              </div>
            </div>
            
          </div>
          <div>
          <p style={{color:'black',fontSize:'10px',marginBottom:"2rem",marginLeft:"-10rem"}}>Listing Date & Time :{item.date_posted}</p>
          </div>
          {item.sold_out === false ? <button className="buy--btn" onClick={() => navigate(`/enquiry/${item.id}`) & alert(`Remember !! Your Chosen Property ID is : " ${item.id} " Remember this for the further procedure...`) & scrollToTop()} >Enquiry now</button>
          : <button className="buy--btn" disabled style={{background:"gray"}}>Enquiry now</button>}
        </div>
        {item.sold_out === true ? <img src={soldOut} alt="" srcset="" className="soldout" /> : ""}
      </section>
    </div>
    
      </>
    );
  };

  return (
    <>
     <Header />
      <div className="container py-5">
        <div className="row py-4">
          {loading ? <Loading /> : <ShowProduct />}
        </div>
      </div>
     <Footer /> 
    </>
  );
}
