import React, { useEffect,useRef } from 'react';

const PaymentForm = () => {
    const formRef = useRef(null);

    useEffect(() => {
      const formElement = formRef.current;
  
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/payment-button.js';
      script.setAttribute('data-payment_button_id', 'pl_M6UjTFETbR1DqT');
      script.async = true;
      formElement.appendChild(script);
  
      return () => {
        formElement.removeChild(script);
      };
    }, []);
  
    return (
      <form ref={formRef} id='paymentForm'>
        {/* Render any additional form fields or elements here */}
      </form>
    );
};

export default PaymentForm;
