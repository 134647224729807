import React from 'react'
import '../home/Featured.css'
import Header from '../Header/Header'
import Footer from './Footer'

const ServiceContact = () => {
  return (
    <>
    <Header />
    <div className="fc-form-con">
        <iframe className="fc-gfrom" src="https://docs.google.com/forms/d/e/1FAIpQLSfPCL7DfXy0AvY263fmSQesGCIRTZYUOC-apxwTmk2nm8cvsQ/viewform?embedded=true" >Loading…</iframe>
    </div>
    <Footer />
    </>
  )
}

export default ServiceContact
