import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { useImmerReducer } from "use-immer";

// Contexts
import StateContext from "../../Contexts/StateContext";


// Components



// MUI
import {
	Grid,
	Typography,
	Button,	
	TextField,
	Snackbar
} from "@mui/material";


function Stp2part(props) {
	const navigate = useNavigate();
	const GlobalState = useContext(StateContext);
    

	const initialState = {
		fullNameValue: props.userProfile.fullName,
		phoneNumberValue: props.userProfile.phoneNumber,
		uploadedPicture: [],
		profilePictureValue: props.userProfile.profilePic,
		sendRequest:0,
		openSnack: false,
		disabledBtn: false,
	};

	function ReducerFuction(draft, action) {
		switch (action.type) {
			case "catchFullNameChange":
				draft.fullNameValue = action.fullNameChosen;
				break;

			case "catchPhoneNumberChange":
				draft.phoneNumberValue = action.phoneNumberChosen;
				break;

			case "catchUploadedPicture":
				draft.uploadedPicture = action.pictureChosen;
				break;

			case "catchProfilePictureChange":
				draft.profilePictureValue = action.profilePictureChosen;
				break;

			case "changeSendRequest":
				draft.sendRequest = draft.sendRequest + 1;
				break;

			case "openTheSnack":
				draft.openSnack = true;
				break;

			case "disableTheButton":
				draft.disabledBtn = true;
				break;

			case "allowTheButton":
				draft.disabledBtn = false;
				break;
		}
	}

	const [state, dispatch] = useImmerReducer(ReducerFuction, initialState);

	//use effect to catch uploaded picture
	useEffect(() => {
		if (state.uploadedPicture[0]) {
			dispatch({
				type: "catchProfilePictureChange",
				profilePictureChosen: state.uploadedPicture[0],
			});
		}
	}, [state.uploadedPicture[0]]);
	


	//form submit 
    function FormSubmit(e) {
		e.preventDefault();
		dispatch({ type: "changeSendRequest" });
		dispatch({ type: "disableTheButton" });
	}

		// use effect to send the request
		useEffect(() => {
			if (state.sendRequest) {
				async function UpdateProfile() {
					const formData = new FormData();
	
					if(typeof state.profilePictureValue === "string" || state.profilePictureValue === null){
						formData.append("full_name", state.fullNameValue);
						formData.append("phone_number", state.phoneNumberValue);
						formData.append("person", GlobalState.userId);
					}
					else{
						formData.append("full_name", state.fullNameValue);
						formData.append("phone_number", state.phoneNumberValue);
						formData.append("profile_picture", state.profilePictureValue);
						formData.append("person", GlobalState.userId);
					}	
	
	
					try {
						const response = await Axios.patch(
							`https://ojiservices.online/api/profiles/${GlobalState.userId}/update/`,
							formData
						);
						dispatch({ type: "openTheSnack" });
						alert("Congratution !! you have updated your profile..Now you are redirected to the Post Requirement page....")
                        navigate("/postrequirement")
                        scrollToTop()
					} catch (e) {
						dispatch({ type: "allowTheButton" });
					}
				}
				UpdateProfile();
			}
		}, [state.sendRequest]);

		useEffect(() => {
			if (state.openSnack) {
				setTimeout(() => {
					navigate(0);
				}, 1500);
			}
		}, [state.openSnack]);

		const scrollToTop = () => {
			window.scrollTo(0, 0)
		}

		function ProfilePictureDisplay(){
			if(typeof state.profilePictureValue !== "string"){
				return (
					<p style={{marginLeft:"auto",marginRight:"auto"}}>
						{state.profilePictureValue ? (
							state.profilePictureValue.name
						):(
							""
						)}
					</p>
				);
			}else if (typeof state.profilePictureValue === "string"){
				return(
					<Grid sx={{marginLeft:"auto",marginRight:"auto",marginTop:"1rem"}}>
					<img src={props.userProfile.profilePic} style={{height:"5rem",}}/>
				</Grid>
				)
			}
		}
		

    return (
        <>
        <div >
            <Typography style={{}}>
            <div
				style={{
					width: "70%",
					marginLeft: "auto",
					marginRight: "auto",
					marginTop: "3.5rem",
					boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    borderRadius:"6px",
					padding: "3rem",
				}}
			>
				<form onSubmit={FormSubmit}>
					<Grid item container justifyContent="center" textAlign={"center"}>
						<Typography variant="h4">MY PROFILE</Typography>
					</Grid>
					<p style={{fontSize:"11px",textAlign:'center',marginTop:"1rem"}}>**If you have already updated your profile then just click on "Update & Go to form page" **</p>
					<Grid item container style={{ marginTop: "1rem" }}>
						<TextField
							id="fullName"
							label="Full Name"
							variant="outlined"
							fullWidth
							required
							value={state.fullNameValue }
							onChange={(e) =>
								dispatch({
									type: "catchFullNameChange",
									fullNameChosen: e.target.value,
								})
							}
						/>
					</Grid>

					<Grid item container style={{ marginTop: "1rem" }}>
						<TextField
							id="phoneNumber"
							label="Phone Number"
							variant="outlined"
							fullWidth
							required
							value={state.phoneNumberValue}
							onChange={(e) =>
								dispatch({
									type: "catchPhoneNumberChange",
									phoneNumberChosen: e.target.value,
								})
							}
						/>
					</Grid>
                    <Grid item container style={{ marginTop: "1rem" }}>
						<TextField
							id="phoneNumber"
							label="Email"
							variant="outlined"
							fullWidth
							value={GlobalState.userEmail}
							disabled
						/>
					</Grid>

					<Grid item container>
						{ProfilePictureDisplay()}
					</Grid>

					<Grid
						item
						container
						xs={6}
						style={{
							marginTop: "1rem",
							marginLeft: "auto",
							marginRight: "auto",
						}}
					>
						<Button
							variant="contained"
							component="label"
							fullWidth
							sx={{
								backgroundColor: "goldenrod",
								color: "white",
								fontSize: "0.7rem",
								"&:hover":{
									background:"black",
								}
							}}
						>
							PROFILE PICTURE
							<input
								type="file"
								accept="image/png, image/gif, image/jpeg"
								hidden
								onChange={(e) =>
									dispatch({
										type: "catchUploadedPicture",
										pictureChosen: e.target.files,
									})
								}
								style={{}}
							/>
						</Button>
					</Grid>
					

					<Grid
						item
						container
						xs={8}
						style={{
							marginTop: "1rem",
							marginLeft: "auto",
							marginRight: "auto",
						}}
					>
						<Button
							variant="contained"
							className="prf-btn"
							fullWidth
							type="submit"
                            // onClick={() => navigate("/postrequirement") & scrollToTop()}
							sx={{
								backgroundColor: "orangered",
								color: "white",
								fontSize: "1rem",
								
								"&:hover":{
									background:"black",
								}
							}}
							
							disabled={state.disabledBtn}
						>
							Update & Go to Form Page 
						</Button>
					</Grid>
					<Snackbar
					open={state.openSnack}
					message="You have successfully updated your profile!"
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
				/>
				</form>
                </div>
            </Typography>
        </div>
        </>
    )
    
}

export default Stp2part;