import React, { useContext } from 'react';
// import ReactDOM from "https://cdn.skypack.dev/react-dom@17.0.1";
import { useState, useEffect } from 'react';
import './Layout.scss'
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import StateContext from '../../Contexts/StateContext';



function Rent() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [q, setQ] = useState("");
    const [searchParam] = useState(["place", "area", "price"]);
    const [filterParam, setFilterParam] = useState(["All"]);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(Infinity);
    const GlobalState=useContext(StateContext)
    const navigate=useNavigate();

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
     const handleMinPriceChange = (event) => {
        setMinPrice(parseInt(event.target.value));
      };
    
      const handleMaxPriceChange = (event) => {
        setMaxPrice(parseInt(event.target.value));
      };


      const filteredProducts = items.filter((product) => {
   
        // Check the price range
        if (minPrice !== 0 && product.price < minPrice) {
          return false;
        }
    
        if (maxPrice !== Infinity && product.price > maxPrice) {
          return false;
        }
    
        return true;
      });

    useEffect(() => {
        fetch(
            "https://ojiservices.online/api/listing/?id=&category=Rent&propertyType="
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    }, []); 

    const data = Object.values(filteredProducts)
  
    function search(filteredProducts) {
        return filteredProducts.filter((item) => {
            if (item.propertyType == filterParam) {
                return searchParam.some((newItem) => {
                    return (
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(q.toLowerCase()) > -1
                    );
                });
            } else if (filterParam == "All") {
                return searchParam.some((newItem) => {
                    return (
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(q.toLowerCase()) > -1
                    );
                });
            }
        });
    }

    if (error) {
        return (
           <div className='layout-body'>
             <p>
                {error.message}, Sorry for your inconvinience.... There is some technical issues..{" "}
                <a href="https://ojiservices.in/">
                    {" "}
                    check it out{" "}
                </a>{" "}
            </p>
           </div>
        );
    } else if (!isLoaded) {
        return (
        <>
        <Header />
        <div className='layout-body' style={{marginTop:"4.5rem"}}>loading...</div>
        <Footer />
        </>);
    } else {
        return (
           <>
           <Header />
           <div className='layout-body' style={{marginTop:"4.5rem",top:"-2rem"}}>
                <div className="wrapper">
                 
                <div className="search-wrapper" >
                    <label htmlFor="search-form" >
                        <input
                            type="search"
                            name="search-form"
                            id="search-form"
                            className="search-input"
                            placeholder="Search by location"
                            value={q}
                            onChange={(e) => setQ(e.target.value) }
                            
                        />
                        <span className="sr-only">Search PropertyType here</span>
                    </label>

                    <div className="srch-price" >
                        <h3 style={{textAlign:'center',marginBottom:"1rem"}}>PRICE FILTER</h3>
                        <div className='srch-price-div' >
                            <span>
                                <input type="number" min={0} name="minPrice" placeholder="Min Price" onChange={handleMinPriceChange}  className='srch-min' />
                            </span>
                        <span>
                            <input type="number" min={0} name="maxPrice" placeholder="Max Price" onChange={handleMaxPriceChange} className='srch-max'/>
                        </span>
                        
                    </div>
                    </div>

                    <div className="select" style={{marginTop:"3rem"}}>
                        <select
                            onChange={(e) => {
                                setFilterParam(e.target.value);
                            }}
                            className="custom-select"
                            aria-label="Filter PropertyType By Region"
                        >
                            <option value="All">Filter By PropertyType</option>
                            <option value="1-BHK Apartment">1-BHK Apartment</option>
                            <option value="2-BHK Apartment">2-BHK Apartment</option>
                            <option value="3-BHK Apartment">3-BHK Apartment</option>
                            <option value="4-BHK Apartment">4-BHK Apartment</option>
                            <option value="Studio">Studio</option>
                            <option value="Office">Office</option>
                            <option value="House">House</option>
                            <option value="Duplex">Duplex</option>
                            <option value="PG">PG</option>
                            <option value="Hostel">Hostel</option>
                            <option value="Land">Land</option> 
                        </select>
                        <span className="focus" style={{border:"none",overflow:"hidden",maxWidth:"100%"}}></span>
                    </div>
                </div>
                <ul className="card-grid" style={{marginTop:"-1rem"}}>
                    {search(data).map((item) => (
                        <li>
                            <article className="card" key={item.id}>
                                <div className="card-image">
                                    <img
                                        src={item.picture1}
                                        alt={item.title}
                                    />
                                </div>
                                <div className="card-content">
                                    <h2 className="card-name">{item.title}</h2>
                                    <ol className="card-list">
                                        <li>
                                            Area:{" "}
                                            <span>{item.area}</span>
                                        </li>
                                        <li>
                                            Place: <span>{item.place}</span>
                                        </li>
                                        <li>
                                            Property Type: <span>{item.propertyType}</span>
                                        </li>
                                        <li>
                                            Price: <span> Rs. {item.price}</span>
                                        </li>
                                        <Link to={`/rentproperty/${item.id}`} className='layout-btn' target='_top'>
                                            <button><h4>GET DETAILS</h4></button>
                                        </Link>
                                    </ol>
                                </div>
                            </article>
                        </li>
                    ))}
                </ul>
                <div 
                style={{width:"90%",
                display:"flex",
                flexDirection:"column",
                justifyContent:"space-around",
                alignItems:"center",
                gap:"1rem",
                boxShadow:"2px 2px 25px -7px #4c4c4c",
                padding:'5%',
                borderRadius:"8px",
                marginLeft:"auto",
                marginRight:"auto"}}>
                    <h2 style={{textAlign:"center",
                    textTransform:"capitalize",
                    fontSize:"20px",
                    marginBottom:"5rem"}}>If you not found your desired property then Click on the below button and post your requirements</h2>
                    {GlobalState.userIsLogged ? <button className="buy--btn" onClick={() => navigate('./update_profile') & alert('Update your profile and post your requirements...') & scrollToTop()} >Post Requirement</button>
          : <button className="buy--btn" onClick={() => navigate('/signin') & alert('First you need to "Login" to your account...') & scrollToTop()}>Post Requirement</button>}
                </div>
            </div>
            </div>
           <Footer />
           </>
        );
    }
}
export default Rent;