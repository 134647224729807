import React from 'react'
import '../AboutUs/AboutUs.css'
import Header from '../Header/Header'
import Footer from './Footer'
import { Link } from 'react-router-dom';
import termImg from './closeup-hands-passing-contract-unrecognizable-businessman.jpg'

const TermsConditions = () => {
  return (
    <>
    <Header />
        <div className="abtus-wrapper" style={{marginTop:"4.5rem"}}>
            <div className="abtus-container">
                <h2>Terms &amp; Conditions:</h2>
                <div className="abtus-item">
                    
                    <div className="abtus-left">
                    <h3>Our Role:</h3>
                    <p>First of all any property added by a House Owner / Builder will be first physically verified by OJI
                        CONSULTACY PVT. LTD for easy wide road of approach, safety, day today modern conveniences
                        like school, college, market complex, Hospital, Mall, Park, Bus stand, 24 X 7 electricity &amp; water
                        Supply etc. We will verify the original documents of House Owner like adhar card, electric bill
                        etc. Then after interior conditions of house like color, utility services, garage / parking space
                        everything will be verified physically.
                    </p>

    
                    </div>
                    <div className="abtus-right">
                        <img src={termImg} alt=""  />
                    </div>
                </div>
                
                <div className="abtus-items">
                    <p>
                    Then after discussion mutually regarding all terms and conditions the legal lease agreement will
                    be signed by both the parties the HOUSE OWNER &amp; OJI CONSULTANCY PVT. LTD. at least for 5
                    year to maximum 9 years of lease period. OJI CONSULTANCY PRIVATE LIMITED will do the
                    payment to the House owner as per their terms and conditions.
                    </p>
                    <p style={{fontWeight:"bold"}}>That means OJI CONSULTANCY PRIVATE LIMITED will sublease the same property
again. Here and after OJI CONSULTANCY PRIVATE LIMITED will be called the Lessor.</p>
                    <p>Now the properties will be listed by OJI CONSULTANCY PRIVATE LIMITED in the website for
                        rental purpose of who will temporary occupy the property, Herein after called the Lessee.</p>
                        Then after KYC, source and nature of income of LESSEE along with the motto of occupation /
                        rent will be verified by OJI CONSULTANCY PVT. LTD.
                    <p>Now again a fresh agreement will be prepared between the Lessee and Lessor and after deposit
                    of required security deposit the property will be handover temporarily to the Lessee for the
                    short term stipulated time period.</p>
                    <p>The security deposit will be refunded to the Lessee after vacate of the property in time frame
                    with the same condition as they have taken from the Lessor.</p>
                    <p>Here both the House owner as well as User / the Lessee have to register with
                    OJISERVICES.IN</p>
                    <p>Here OOJISERVICES.IN will provide a third party Payment Gateway to the Lessee for easy
                    seamless rental payment to the Lessor. By using this platform for making rental payments, the
                    User confirms that there is a valid Lessor and Lessee relationship between the User and the
                    Beneficiary and the amount being transferred through the Platform is representing the actual
                    rental value as per the Agreement. We are only providing a platform to facilitate these rental
                    payments from the User to the Beneficiary. The Lessee needs to comply with the relevant
                    regulations to such payments and shall be responsible for any non-compliance or defaults in
                    such payments. We are not a financial institution and do not offer any sort of banking or
                    related financial services. We may use the services of one or more third parties to provide the
                    services and process your transactions. We are neither an agent of the Lessor or the Lessee or
                    any interested party in any such remittance.</p>
                    <h3>KYC:</h3>
                    <p>While you will be completely responsible for proving the authenticity of the transaction,
                    OJISERVICES.IN may ask for requisite documents or details of the User / Lessee and the
                    Beneficiary/ House owner including any document to substantiate the Lessor and Lessee
                    relationship between them. Example: Rental Agreement, PAN Card, Aadhar Card, etc. In case of
                    the amount of remittance exceed INR 50,000/-, the User shall be required to provide the PAN
                    card details or any such document / information as required under the law from time to time.
                    During Aadhaar based verification, you authorize OJI CONSULTANCY PVT. LTD to download your
                    Aadhaar XML from UIDAI website to verify your KYC details.</p>
                    <h3>Authorization:</h3>
                    <p>By initiating this transaction on the Platform, you authorize us to hold, receive and disburse
                    funds in accordance with your payment instructions to the designated bank account of the
                    Beneficiary. Your authorization permits us to charge the mentioned amount to your Bank
                    Account, Credit Card, UPI Account, Debit Card, Payment Wallets or any other Payment
                    Instruments as may be enabled on our platform from time to time for remitting the amount to
                    the designated Bank account of the Beneficiary. When you instruct us to pay the Beneficiary,
                    you authorize and order us to commit your payment (less any applicable fees, Service Charges,
                    or other amounts that we may collect under this Agreement) to such person. In case, the
                    transaction is not processed due to incomplete details being provided by you then we shall
                    refund the amount to you after deducting the applicable Service Charges, Transaction fees, and
                    penalty amount.</p>
                    <h3>Transaction terms:</h3>
                    <p>You would be responsible for entering the correct bank accounts details of the Beneficiary at
                    the time of making the transaction and in no circumstances, OJISERVICES.IN shall be
                    held responsible for any wrongful credit due to incorrect bank details. The user would be
                    responsible for any fraudulent transactions that happened through his bank account, credit
                    card account, etc. by any third party due to his negligence and/or providing your bank or card
                    details to any third party. In no circumstances, OJISERVICES.IN shall be held responsible
                    for any such fraudulent transactions and no refund shall be made by OJISERVICES.IN
                    once the money is successfully credited to the designated bank account of the Beneficiary. We
                    may delay, suspend or reject a payment(s) transaction for any reason, including without
                    limitation if we suspect the transaction subjects us to financial or security risk or is
                    unauthorized, fraudulent, suspicious, unlawful, in violation of the terms of this Agreement,
                    subject to dispute or otherwise unusual.</p>
                    <h3>Liability:</h3>
                    <p>You agree not to hold OJISERVICES.IN responsible and/or liable for any issue or claim
                    arising out of any dispute whatsoever between you and/or the recipient and/or any financial
                    institution. You agree to hold harmless OJISERVICES.IN for any transaction done by you
                    with incorrect details etc. which gets credited in any wrong account. You agree to fully
                    indemnify and hold harmless OJISERVICES.IN, its affiliates, and employees against any claim or
                    action by any person or regulatory authorities.</p>
                    <h3>Suspicious / Fraudulent transactions:</h3>
                    
                    <p>The User is supposed to immediately notify us in case any suspicious/fraudulent transaction is
                    observed by him. It is the User’s responsibility to keep his financial details strictly confidential
                    and not share them with any other person. As soon as any suspicious / fraudulent transactions
                    get reported, we shall take the necessary measures and try to stop the same. However, we shall
                    under no circumstances be liable for any refund, etc. to any person / Authority for such
                    transactions if the money is credited to the designated bank account of the Beneficiary as
                    provided by the User.</p>
                    <h3>Terms and Conditions applicable on user generated Content:-</h3>
                    <p>Please read the terms and conditions of the below stated disclaimer carefully. This is a
                    legally binding agreement between you and the Publisher i.e.OJISERVICES.IN,
                    establishing the terms and conditions under which this website may be viewed and
                    used. By viewing this website, or any pages thereof, and/or using the information
                    provided in or via the website, you agree to be bound by this disclaimer and the terms
                    contained therein.</p>
                    <p style={{fontWeight:"bold"}}>The User viewing the content on OJISERVICES.IN agrees to the following:</p>
                    <ul>
                    <li>Content does not belong to OJISERVICES.IN and has been independently provided by
                    other Users like property owners / brokers.</li>
                    <li>OJISERVICES.IN is only providing a platform to the users to showcase their properties
                    for rent and therefore the respective Users alone shall be legally responsible for their content.
                    OJISERVICES.IN has no control over the content and assumes no responsibility for it.</li> 
                    <li>Any use or reliance on any Content or materials posted by any other user on
                    OJISERVICES.IN is at the user’s own risk. OJISERVICES.IN does not endorse,
                    support, represent or guarantee the completeness, truthfulness, accuracy, or reliability of any
                    user generated Content posted on its website.</li>
                    <li>The User understands that the Content might be offensive, harmful, inaccurate, otherwise
                    inappropriate, or in some cases, it might have been mis-labelled or otherwise deceptive.</li>
                    <li>If the User notices any Content that he believes is in violation of the law or is inappropriate
                    then he can report it to us at info@ojiservices.in and we shall take appropriate action on
                    it within 48 hours.</li>
                    </ul>
                    <p style={{fontWeight:"bold"}}>The User posting the content on OJISERVICES.IN agrees to the following:</p>
                    <ul>
                    <li>We have ensured that the Content does not violate any law or anyone’s intellectual property
                    rights. Besides, we also guarantee that the Content does not display anything objectionable
                    like nudity, violence, harassment, hate speech, terrorism, false news, sexual activity, child
                    abuse, promote the use of intoxicants, self-injury of any sort, etc.</li>
                    <li>We accept the complete legal responsibility of the Content provided by us to
                    OJISERVICES.IN.</li>
                    <li>OJISERVICES.IN has no control over the Content and therefore it shall have no
                    responsibility for the same.</li>
                    <li>OJISERVICES.IN shall be under no obligation to host or serve the Content.</li>
                    <li>If OJISERVICES.IN reasonably believes that the Content is in violation of the legal
                    provisions including, for example, copyright or trademark violations or other intellectual
                    property misappropriation, impersonation, unlawful conduct, or harassment or may cause
                    harm to it, its users, or third parties, it may at any time remove or take down the Content at
                    its sole discretion and we shall have no objection to the same.</li>
                    <li>By providing Content to OJISERVICES.IN, we grant it a worldwide, non-exclusive,
                    royalty-free, transferable, sub licensable right to use the Content (including to reproduce,
                    distribute, prepare derivative works, display and perform it)..</li>
                    <li>The Listings, User details, leads, etc. shall be treated as the property of the company and can
                    be used by the company or any of its partners/affiliates/associates, etc.</li>
                    <li>The Users agree and grant the right to the company and any of its partners / affiliates /
                    associates etc. to send promotional messages, SMS’s, calls of their own, or any third-party
                    product to the Users.</li>
                    </ul>
                    <h3 style={{color:"red",textAlign:"center",textDecoration:"underline"}}>Legal Disclaimer</h3>
                    <p>OJISERVICES.IN transfuses information / Data provided and submitted by
                    marketing agents, advertisers, content partners, software developers, publishers,
                    employees, website users, and other third parties. While any attempt has been made to
                    trace the authenticity of the content on the Platforms OJISERVICES.IN has no
                    control over content, the accuracy of such content, integrity or quality of such content
                    and the information on our web/pages, and material on the Platforms may include
                    technical inaccuracies or typographical errors, and we make no guarantees, nor can we
                    be responsible for any such information, including its authenticity, content quality,
                    copyright compliance or legality, or any other intellectual property rights compliance, or
                    any resulting loss or damage.</p>
                    <p>The Publisher OJISERVICES.IN reserves the right to change the information
                    provided on or via this website, including the terms of this disclaimer, at any time and
                    without notice. However, the Publisher shall not undertake to update the Content
                    contained in this website as provided from time to time. It is recommended that you
                    review the information provided on or via this website, including the terms of this
                    disclaimer, periodically for changes. The Publisher shall not be held responsible for all or
                    any actions that may subsequently result in any loss, damage and or liability on account
                    of such change in the information on this website.</p>
                    <p>OJI CONSULTANCY PVT. LTD and its affiliates are not responsible for the content,
                    functionality, authenticity or technological safety of these external sites. We reserve the
                    right to disable links to or from third-party sites to any of our Platforms, although we are
                    under no obligation to do so. This right to disable links includes links to or from
                    advertisers, sponsors, and content partners that may use our Marks as part of a co-
                    branding relationship.</p>
                    <p>OJISERVICES.IN may periodically change the Terms and the Site without notice,
                    and you are responsible for checking these Terms periodically for revisions, Some
                    external links may produce information that some people find objectionable,
                    inappropriate, or offensive. We are not responsible for the accuracy, relevancy,
                    copyright compliance, legality, or decency of material contained in any externally linked
                    websites. We do not fully screen or investigate property listing websites before or after
                    including them in website property listings that become part of the Materials on our
                    Platforms, and we make no representation and assume no responsibility concerning the
                    content that third parties submit to become listed in any of these listings.</p>

                    <p>OJISERVICES.IN also reserves the right to impose/change the access regulations
                    of the Platforms, whether in terms of access fee, timings, equipment, access restrictions
                    or otherwise, which shall be posted from time to time under these terms and
                    conditions. It is the responsibility of users to refer to these terms and conditions each
                    time they use the Platforms.</p>
                    <p>While every attempt has been made to ascertain the authenticity of the content in the
                    Platforms, OJI CONSULTANCY PVT. LTD is not liable for any kind of damages, losses or
                    action arising directly or indirectly, due to access and / or use of the content in the
                    Platforms including but not limited to any decisions based on content in the Platforms
                    resulting in loss of data, revenue, profits, property, infection by viruses etc. . If you
                    &quot;website user&quot;, take any information from our website or we provide any information to
                    you &quot;website user&quot; then verification or authenticity of any service will be confirm from
                    your side, OJISERVICES.IN will not responsible for any verification and
                    authenticity of any given information.</p>
                    <p>The Content (material, information, data, commercials, etc.) contained on this website
                    are provided for general information and personal non-commercial use only and should
                    not be used as a basis for making business/commercial decisions. You are advised to
                    exercise due caution and/or seek independent advice before entering into any
                    investment or financial obligation based on the Content contained on this website. 
                    Do not send us any confidential or proprietary information. Except for any personally
                    identifiable information that we agree to keep expressly confidential, any material,
                    including, but not limited to any feedback, data, answers, questions, comments,
                    suggestions, ideas or the like, which you send to us will be treated as being non-
                    confidential and nonproprietary. We assume no obligation to protect confidential or
                    proprietary information (other than personally identifiable information) from disclosure
                    and will be free to reproduce, use, and distribute the information to others without
                    restriction. We will also be free to use any ideas, concepts, know-how or techniques
                    contained in information that you send us for any purpose whatsoever including but not
                    limited to developing, manufacturing and marketing products and services incorporating
                    such information.</p>
                    <p>If you would like to link to this Website, you may only do so on the basis that you link to,
                    but do not replicate, any page on this Website and you do not in any way imply that we
                    are endorsing any services or products unless this has been specifically agreed with us. </p>

                    <p> The Publisher OJISERVICES.IN shall not be liable to you for any indirect,
                    incidental, punitive, special or consequential loss, damage, cost or expense including,
                    without limitation, loss of profits, loss of data, and loss of revenues, of any kind
                    whatsoever and however caused, whether arising under contract, tort (including
                    negligence or breach of statutory duty) or otherwise, even if the Publisher has been
                    advised of its possibility. </p>
                    <p>The failure at any time of the Publisher OJISERVICES.IN to demand strict
                    performance by the other of any of the terms, covenants or conditions set forth herein
                    shall not be construed as a continuing waiver or relinquishment thereof, and the
                    Publisher may, at any time, demand strict and complete performance by the other of
                    such terms, covenants and conditions. </p>
                    <p>The invalidity or unenforceability of any provision in this Agreement shall in no way
                    affect the validity or enforceability of any other provision herein. </p>
                    <p>Neither the Publisher OJISERVICES.IN nor the user shall be liable for any loss to
                    the other caused by the failure to observe the terms and conditions of this Agreement,
                    wherein such failure is occasioned by any cause beyond the other party’s reasonable
                    control such as act of God, war, insurrection, riot, fire, flood, epidemic, earthquake,
                    strikes, lock-outs, labor controversy, civil commotion, act of terrorism, any change /
                    repudiation of law, statute, act, rules, regulations, policies, bye-laws or similar cause. 
                    This Agreement constitutes the entire agreement between the Publisher and the User
                    with respect to the subject matter of Agreement. </p>
                    <p>Nothing in this Agreement shall be deemed to create any agency, partnership, joint
                    venture unincorporated association, co-operative entity or other joint relationship
                    between the parties hereto or constitute any party the agent of the other party for any
                    purpose or entitle any party to commit or bind the other party in any manner or give
                    rise to fiduciary duties by one party in favor of the other party. 
                    This website and disclaimer shall be governed by and construed in accordance with all
                    applicable laws of India. All disputes arising out of or in connection with this disclaimer
                    or use of the website shall be submitted to the exclusive jurisdiction of the courts of
                    Odisha High Court. </p>
                    <p>OJISERVICES.IN reserves the right to investigate complaints or reported
                    violations of these Terms and to take any action we deem necessary and appropriate.
                    Such action may include reporting any suspected unlawful activity to law enforcement
                    officials, regulators, or other third parties. In addition, we may take action to disclose
                    any information necessary or appropriate to such persons or entities relating to user
                    profiles, e-mail addresses, usage history, posted materials, IP addresses and traffic
                    information. OJISERVICES.IN reserves the right to seek all remedies available at
                    law and in equity for violations of these Terms.</p>
                    <h3>Amendment to the Terms of Service: </h3>
                    <p>We reserve the right, at our sole discretion, to change, modify, add or remove portions of these
                    Terms of Use, at any time without any prior written notice to you. It is your responsibility to
                    review these Terms of Use periodically for updates / changes. Your continued use of the
                    Services following the posting of changes will mean that you accept and agree to the revisions.</p>
                    <h3>Arbitration:</h3>
                    <p>If any dispute arises between a user/users and OJISERVICES.IN arising out of use of the
                    Website or thereafter, in connection with the validity, interpretation, implementation or
                    alleged breach of any provision of these terms and conditions, the dispute shall be referred to a
                    sole Arbitrator who shall be an independent and neutral third party identified by
                    OJISERVICES.IN. Decision of the Arbitrator shall be final and binding on both the parties
                    to the dispute. The place of arbitration shall be Bhubaneswar, Odisha. The Arbitration &amp;
                    Conciliation Act, 1996, shall govern the arbitration proceedings.</p>
                    <h3>Grievance Redressal Mechanism:</h3>
                    <p>Any complaints, abuse or concerns with regards to content and or comment or breach of these
                    terms shall be immediately informed to the Grievance Officer through email on the following
                    email Id info@ojiservices.in. Any grievance so received by the Company shall be
                    resolved within the best possible time.</p>

                    <p style={{color:"red",textAlign:"center"}}>Please exit OJISERVICES.IN or any our Website, if you don&#39;t agree to
                    these Terms.</p>
                </div>
            </div>
        </div>
    <Footer />
    </>
  )
}

export default TermsConditions