import React from 'react';
import "./CustomSearch.css";
import SearchIcon from '@mui/icons-material/Search';
import { Button } from '@mui/material';


const CustomSearch = () => {
  return (
    <>
      {/* <section className='hero'> */}
        <div className='container'>

          <div className='flex' style={{fontFamily:"monospace",fontSize:"20px"}}>
           
            <div className="buy__btn">
           ** Currently Our Service Is Provided at Bhubabanewar only **
            </div>
          </div>
        </div>
      {/* </section> */}
    </>
  )
}

export default CustomSearch;