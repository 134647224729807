import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Axios from 'axios'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Snackbar , Alert } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect,useContext } from "react";
import StateContext from "../../Contexts/StateContext";
import { useImmerReducer } from 'use-immer';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const defaultTheme = createTheme();

const CostumerReview = () => {

    // const { id } = useParams();
    // const [item, setItem] = useState([]);
    const [loading, setLoading] = useState(false);
    const GlobalState = useContext(StateContext);
    
    const navigate = useNavigate();

  const initialstate = {
    nameValue:'',
    ratingValue: '',
    feedbackValue: '',
    sendRequest: 0,
    openSnack: false,
    disabledBtn: false,
  };

  function ReducerFunction(draft,action){
    switch (action.type){
      case 'catchNameChange':
        draft.nameValue = action.nameChosen;
        break;

      case 'catchRatingChange':
        draft.ratingValue = action.ratingChosen;
        break;

      case 'catchFeedbackChange':
        draft.feedbackValue = action.feedbackChosen;
        break;
           
      case 'changeSendRequest':
        draft.sendRequest = draft.sendRequest + 1;
        break;

      case "openTheSnack":
        draft.openSnack = true;
        break;

      case "disableTheButton":
        draft.disabledBtn = true;
        break;

      case "allowTheButton":
        draft.disabledBtn = false;
        break;
      
    }
  }
  const [state, dispatch] = useImmerReducer(ReducerFunction,initialstate);

    //submit function
    const handleSubmit = (event) => {
        event.preventDefault();
        if(GlobalState.userIsLogged)
        {dispatch({ type: "changeSendRequest" });
        dispatch({ type: "disableTheButton" });	
      }else{
        alert("Plese 'SignIn' to your accont first to post your review")
        navigate("/signin")
      }
      };

      //send values to backend
      useEffect(() => {
		if (state.sendRequest) {
			async function CostumerReview() {
				const formData = new FormData();
				formData.append("costumer_email",GlobalState.userEmail);
				formData.append("costumer_name", state.nameValue);
				formData.append("costumer_rating", state.ratingValue);
				formData.append("costumer_feedback", state.feedbackValue);
				

				try {
					const response = await Axios.post(
						"https://ojiservices.online/api/costumer_reviews/create/",
						formData
					);
					dispatch({ type: "openTheSnack" });
					alert("Thankyou for giving your valuable reviews...")
				} catch (e) {
					dispatch({ type: "allowTheButton" });
				}
			}
			CostumerReview();
		}
	}, [state.sendRequest]);
    
      useEffect(() => {
            if (state.openSnack) {
                setTimeout(() => {
                    navigate("/");
                }, 1500);
            }
        }, [state.openSnack]);

        const scrollToTop = () => {
          window.scrollTo(0, 0)
      }

  return (
    <>
    <Header />
    <div className='signup-wrapper' style={{marginTop:"8rem"}}>
      <div className='signup-container'>
        <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          
         
         {GlobalState.userIsLogged ? (
           <Typography compoenent="h1" variant="h5" sx={{marginTop:"1rem",fontFamily:"monospace",textAlign:"center"}}>
           Give your valuable Review
         </Typography>
         ):( <Typography compoenent="h1" variant="h6" sx={{marginTop:"1rem",fontFamily:"monospace",textAlign:"center",color:"red"}}>
         *** Please First 'SignIn' to your account and post your valuable review ***
       </Typography>)}
          

          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {GlobalState.userIsLogged ? (
                  <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  value={GlobalState.userEmail}
                  disabled
                  onChange={() => {}}
                />
                ):(<TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  value={GlobalState.userEmail}
                  disabled
                  onChange={() => {}}
                />)}
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  label="Full Name"
                //   name="email"
                  type='text'
                  onChange={(e) => dispatch({type: 'catchEmailChange', emailChosen: e.target.value})}
                />
              </Grid> */}
              <Grid item xs={12}>
                {GlobalState.userIsLogged ? (
                  <TextField
                  required
                  fullWidth
                  name="name"
                  label="Full Name"
                  type="text"
                  id="name"
                  
                  value={state.nameValue}
                  onChange={(e) => 
                    dispatch({type: 'catchNameChange',
                              nameChosen: e.target.value})}
                />
                ):(
                  <TextField
                  required
                  fullWidth
                  name="name"
                  label="Full Name"
                  type="text"
                  id="name"
                  disabled
                  value={state.nameValue}
                  onChange={(e) => 
                    dispatch({type: 'catchNameChange',
                              nameChosen: e.target.value})}
                />
                )}
              </Grid>
              
              <Grid item xs={12}>
                {
                  GlobalState.userIsLogged ? (
                    <TextField
                  fullWidth
                  name="feedback"
                  label="Feedback"
                  multiline
                  rows={5}
                  type="text"
                  id="feedback"
                  value={state.feedbackValue}
                  onChange={(e) => 
                    dispatch({type: 'catchFeedbackChange', 
                              feedbackChosen: e.target.value})}
                />
                  ):(
                    <TextField
                  fullWidth
                  name="feedback"
                  label="Feedback"
                  multiline
                  rows={5}
                  type="text"
                  id="feedback"
                  disabled
                  value={state.feedbackValue}
                  onChange={(e) => 
                    dispatch({type: 'catchFeedbackChange', 
                              feedbackChosen: e.target.value})}
                />
                  )
                }
              </Grid>
              <Grid item xs={12}>
              {GlobalState.userIsLogged ? (
                <Box
                sx={{
                    '& > legend': { mt: 1 },
                    color:"GrayText",
                    textAlign:"center",
                    border:"1px solid lightgray",
                    borderRadius:'5px',
                    padding:'2%'
                }}
                >Rate your experience
                <Typography component="legend" ></Typography>
                <Rating
                    name="simple-controlled"
                    value={state.ratingValue}
                    required  
                    size='large'
                    onChange={(e) => 
                        dispatch({type: 'catchRatingChange',
                                  ratingChosen: e.target.value})}
                    sx={{marginLeft:"auto",marginRight:"auto"}}
                />
                </Box>
              ):(
                <Box
                sx={{
                    '& > legend': { mt: 1 },
                    color:"GrayText",
                    textAlign:"center",
                    border:"1px solid lightgray",
                    borderRadius:'5px',
                    padding:'2%'
                }}
                >Rate your experience
                <Typography component="legend" ></Typography>
                <Rating
                    name="simple-controlled"
                    value={state.ratingValue} 
                    size='large'
                    disabled
                    onChange={(e) => 
                        dispatch({type: 'catchRatingChange',
                                  ratingChosen: e.target.value})}
                    sx={{marginLeft:"auto",marginRight:"auto"}}
                />
                </Box>
              )}
              </Grid>
            </Grid>
           {GlobalState.userIsLogged ? (
            <Grid>
               <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 5, mb: 2 ,backgroundColor:'goldenrod' , color: 'black',marginBottom:"3rem"}}
              disabled={state.disabledBtn}
              onClick={scrollToTop}
            >
              Submit
            </Button>
            <Snackbar
                open={state.openSnack}
                message="You have successfully submitted the form!"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              />
            </Grid>
           ):(<Grid>
             <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 5, mb: 2 ,backgroundColor:'goldenrod' , color: 'black',marginBottom:"3rem"}}
              disabled={state.disabledBtn}
              onClick={scrollToTop}
            >
              Go to SignIn Page
            </Button>
           </Grid>)}
          </Box>
            </Box>
          </Container>
        </ThemeProvider>
        </div>
        </div>
        <Footer />
        </>
  )
}

export default CostumerReview