export const nav = [
    {
        text: "home",
        path: "/",
    },
    // {
    //     text: "buy",
    //     path: "/buyproperty",
    // },
    {
      text: "services",
      path: "/services",
    },
    {
      text: "Add Property",
      path: "/addproperty",
    },
    {
        text: "contact",
        path: "/contact",
    },
]


export const featured = [
    {
      cover: '../images/h1.png',
      name: "Land",
      total: "122 Property",
    },
    {
      cover: "../images/h2.png",
      name: "House/Duplex",
      total: "155 Property",
    },
    {
      cover: "../images/h3.png",
      name: "Apartment",
      total: "300 Property",
    },
    
    {
      cover: "../images/h4.png",
      name: "Office/Studio",
      total: "80 Property",
    },
    {
      cover: "../images/h6.png",
      name: "PG/Hostel",
      total: "80 Property",
    },
  ]

  export const services = [
    {
      cover: '../images/rent.jpg',
      name: "Rental Service",
      path:"/rentproperty",
    },
    {
      cover: '../images/drawing.jpg',
      name: "Architectural Design Drawing",
      path:"/services/services_contact",
    },
    {
      cover: '../images/piling.jpg',
      name: "Survey, Soil Testing & Piling",
      path:"/services/services_contact",
    },
    {
      cover: '../images/batching.jpg',
      name: "RMC Supply From Batching Plant",
      path:"/services/services_contact",
    },
    {
      cover: '../images/electrician.jpg',
      name: "Electrician Service",
      path:"/services/services_contact",
    },
    {
      cover: "../images/plumber.jpg",
      name: "Plumber Service",
      path:"/services/services_contact",
    },
    {
      cover: "../images/logistic.jpg",
      name: "Logistic Service",
      path:"/services/services_contact",
    },
    
    {
      cover: "../images/exterminator-white-protective-uniform-standing-by-reservoir-with-chemicals-sprayer.jpg",
      name: "Pest Control Service",
      path:"/services/services_contact",
    },
  ]

  export const whycard = [
    {
      cover:"../images/furnish.png",
    },
    {
      cover:'../images/electrician.jpg'
    },
    {
      cover:'../images/plumber.jpg'
    },
    {
      cover:'../images/logistic.jpg'
    }
  ]

  // export const listingbtn = [
  //   {
  //     text: "All",
  //     path: "/allproperty"
  //   },
  //   {
  //     text: "Buy",
  //     path: "/buyproperty"
  //   },
  //   {
  //     text: "Rent",
  //     path: "/rentproperty"
  //   },
  // ]