import React ,{ useEffect }from 'react'
import { useImmerReducer } from 'use-immer'
import { BrowserRouter ,Routes,Route } from 'react-router-dom'
import Home from '../components/home/Home'
import SignIN from '../components/signin/SignIN'
import SignUp from '../components/signup/SignUp'
import AddProperty from '../components/addProperty/AddProperty'
import CompanyContact from '../components/companyContact/CompanyContact'
import WhyChoose from '../components/home/WhyChoose'
import Options from '../components/home/Options'
import Aboutus from '../components/AboutUs/Aboutus'
import CustomSearch from '../components/home/CustomSearch'
import Layout from '../components/layout/Layout'
import Rent from '../components/layout/Rent'
import Buy from '../components/layout/Buy'
import Product from '../components/details.models/Product'
import Profile from '../components/Profile/Profile'
import DispathContext from '../Contexts/DispathContext'
import StateContext from '../Contexts/StateContext'
import StepOne from '../components/addProperty/StepOne'
import StepTwo from '../components/addProperty/StepTwo'

import ContactUs from '../components/enquiry/ContactUs'
import Apartment from '../components/layout/apartment'
import Pg_hostel from '../components/layout/pg_hostel'
import House_duplex from '../components/layout/house_duplex'
import Office_studio from '../components/layout/land'

import How from '../components/Footer/How'
import CategoryOptions from '../components/Footer/CategoryOptions.'
import WhyUs from '../components/Footer/WhyUs'
import CostumerReview from '../components/CostumerReview/CostumerReview'
import Requirement from '../components/requirement/Requirement'
import Stp2 from '../components/requirement/stp2'
import PrivacyPolicy from '../components/Footer/PrivacyPolicy'
import Cookies from '../components/Footer/Cookies'
import Services from '../components/Footer/Services'
import TermsConditions from '../components/Footer/Terms&Conditions'
import RefundPolicy from '../components/Footer/RefundPolicy'
import CopyRight from '../components/Footer/CopyRight'
import ServiceContact from '../components/Footer/ServiceContact'


export const Pages = () => {

  const initialstate = {
    userUsername: localStorage.getItem("theUserUsername"),
    userEmail: localStorage.getItem("theUserEmail"),
    userId: localStorage.getItem("theUserId"),
    userToken: localStorage.getItem("theUserToken"),
    userIsLogged: localStorage.getItem("theUserUsername") ? true : false,
  };

  function ReducerFunction(draft,action){
    switch (action.type){
      case 'catchToken':
        draft.userToken = action.tokenValue;
        break;
      case "userSignIn":
        draft.userUsername = action.usernameInfo;
        draft.userEmail = action.emailInfo;
        draft.userId = action.idInfo;
        draft.userIsLogged = true;
        break;
      case "logout":
        draft.userIsLogged = false;
        break;
    }
  }
  const [state, dispatch] = useImmerReducer(ReducerFunction,initialstate);

  useEffect(() => {
    if(state.userIsLogged){
      localStorage.setItem('theUserUsername', state.userUsername)
      localStorage.setItem('theUserEmail', state.userEmail)
      localStorage.setItem('theUserId', state.userId)
      localStorage.setItem('theUserToken', state.userToken)
    }else{
			localStorage.removeItem('theUserUsername');
      localStorage.removeItem('theUserEmail');
      localStorage.removeItem('theUserId')
      localStorage.removeItem('theUserToken')
		}
  }, [state.userIsLogged]);

  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <>
    <StateContext.Provider value={state}>
      <DispathContext.Provider value={dispatch}>
      <BrowserRouter>
        
        <Routes>
            <Route exact path='/' Component={Home} />
            <Route path='/addproperty' element={<StepOne />} />
            <Route path='/contact' element={<CompanyContact />} />
            <Route exact path='/signup' Component={SignUp} />
            <Route exact path='/signin' Component={SignIN} />
            <Route exact path='costumer_review/signin' Component={SignIN} />
            <Route path='/whyus' element={<WhyUs />} />
            <Route path='/categories' element={<CategoryOptions />} />
            <Route path='/aboutus' element={<Aboutus />} />           
            <Route path='/findproperty' element={<CustomSearch/>} />
            <Route path='/buyproperty' element={<Buy />} />
            <Route path='/rentproperty' element={<Rent />} />
            <Route path='rentproperty/update_profile' element={<Stp2 />} />
            <Route path='apartments/update_profile' element={<Stp2 />} />
            <Route path='pg_hostels/update_profile' element={<Stp2 />} />
            <Route path='duplex_houses/update_profile' element={<Stp2 />} />
            <Route path='office_studio/update_profile' element={<Stp2 />} />
            <Route path='categories/apartments/update_profile' element={<Stp2 />} />
            <Route path='categories/pg_hostels/update_profile' element={<Stp2 />} />
            <Route path='categories/duplex_houses/update_profile' element={<Stp2 />} />
            <Route path='categories/office_studio/update_profile' element={<Stp2 />} />
            <Route path='/allproperty' element={<Layout />} />
            <Route path='allproperty/:id' element={<Product />} />
            <Route path='buyproperty/:id' element={<Product />} />
            <Route path='rentproperty/:id' element={<Product />} />
            <Route path='addpropertystepone' element={<StepOne />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/updateprofile" element={<StepTwo />} />
            <Route path="/updateprofile/addpropertyform" element={<AddProperty />} />
            <Route path="enquiry/:id" element={<ContactUs />} />
            <Route path='/howitworks' element={<How />} />
            <Route path='/apartments' element={<Apartment />} />
            <Route path='categories/apartments' element={<Apartment />} />
            <Route path='categories/pg_hostels' element={<Pg_hostel />} />
            <Route path='/pg_hostels' element={<Pg_hostel />} />
            <Route path='/duplex_houses' element={<House_duplex />} />
            <Route path='categories/duplex_houses' element={<House_duplex />} />
            <Route path='/office_studio' element={<Office_studio />} />
            <Route path='categories/office_studio' element={<Office_studio />} />
            <Route path='/costumer_review' element={<CostumerReview/>} />
            <Route path='/postrequirement' element={<Requirement />} />
            <Route path='/privacypolicy' element={<PrivacyPolicy />} />
            <Route path='/cookies' element={<Cookies />} />
            <Route path='/services' element={<Services />} />
            <Route path='/terms&conditions' element={<TermsConditions />} />
            <Route path='/refundpolicy' element={<RefundPolicy />} />
            <Route path='/copyrightpolicy' element={<CopyRight />} />
            <Route path='/services/services_contact' element={<ServiceContact />} />
            

            {/* 
            <Route path="/services">
              <Services />
            </Route>
            <Route path="/featured">
              <Featured />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/login_register">
              <Login_Register />
            </Route> */} 
          </Routes>
         
      </BrowserRouter>
      </DispathContext.Provider>
    </StateContext.Provider>
    </>
  )
}
export default Pages;