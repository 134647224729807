import * as React from 'react';
import { useEffect , useContext } from 'react';
import DispathContext from '../../Contexts/DispathContext';
import StateContext from '../../Contexts/StateContext';
import Axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { useImmerReducer } from 'use-immer';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Snackbar , Alert } from "@mui/material";
import {Link, useNavigate } from 'react-router-dom';
import './SignIN.css'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#" style={{color:'inherit'}}>
        Oji Consultancy Pvt. Ltd.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

const SignIN = () => {
  const navigate = useNavigate();
  const GlobalDispatch = useContext(DispathContext);
  const GlobalState = useContext(StateContext);

      const initialstate = {
        usernameValue: '',
        passwordValue: '',
        sendRequest: 0,
        token:'',
        openSnack: false,
        disabledBtn: false,
        serverError: false,
      };
    
      function ReducerFunction(draft,action){
        switch (action.type){
          case 'catchUsernameChange':
            draft.usernameValue = action.usernameChosen;
            draft.serverError = false;
            break;
          case 'catchPasswordChange':
            draft.passwordValue = action.passwordChosen;
            draft.serverError = false;
            break;
          case 'changeSendRequest':
            draft.sendRequest = draft.sendRequest + 1;
            break;
          case 'catchToken' :
            draft.token = action.tokenValue;
            break;
          case "openTheSnack":
            draft.openSnack = true;
            break;
    
          case "disableTheButton":
            draft.disabledBtn = true;
            break;
    
          case "allowTheButton":
            draft.disabledBtn = false;
            break;
    
          case "catchServerError":
            draft.serverError = true;
            break;
        }
      }
      const [state, dispatch] = useImmerReducer(ReducerFunction,initialstate);

      const handleSubmit = (event) => {
        event.preventDefault();  
        dispatch({type: "changeSendRequest"});
        dispatch({ type: "disableTheButton" });
      };

      useEffect(() => {
        if(state.sendRequest){
          const source = Axios.CancelToken.source();
          async function Login() {
            try{
              const response = await Axios.post(
                "https://ojiservices.online/api-auth-djoser/token/login/",
                {
                  username: state.usernameValue,
                  password: state.passwordValue,
                },
                { 
                  CancelToken: source.token ,
                });
               
                dispatch({type: 'catchToken', 
                          tokenValue: response.data.auth_token});
                GlobalDispatch({type: 'catchToken', 
                                tokenValue: response.data.auth_token});

                // navigate('/');
            }catch (error) {
              dispatch({ type: "allowTheButton" });
              dispatch({ type: "catchServerError" });
            }
          };
          Login();
          return () => {
            source.cancel();
          };
        }
      },[state.sendRequest]);
      //get user info
      useEffect(() => {
        if(state.token !== ''){
          const source = Axios.CancelToken.source();
          async function GetUserInfo() {
            try{
              const response = await Axios.get(
                "https://ojiservices.online/api-auth-djoser/users/me/",
                {
                  headers: {Authorization : 'Token '.concat(state.token)}
                },
                { 
                  CancelToken: source.token ,
                });
                console.log(response);
                GlobalDispatch({type: 'userSignIn',
                                usernameInfo: response.data.username,
                                emailInfo: response.data.email,
                                idInfo: response.data.id});
                dispatch({ type: "openTheSnack" });
                alert("Congratulation !! You are logged in your account now...")
            }catch (error) {
              
            }
          };
          GetUserInfo();
          return () => {
            source.cancel();
          };
        }
      },[state.token]);

      useEffect(() => {
        if (state.openSnack) {
          setTimeout(() => {
            navigate("/profile");
          }, 1500);
        }
      }, [state.openSnack]);
    
      const scrollToTop = () => {
        window.scrollTo(0, 0)
      }
    
  return (
    <>
    <Header />
    <div className='signin-wrapper' style={{marginTop:"7rem"}}>
      <div className='signin-container'>
        <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }} className='signin-box'
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} className='signin-icon'>
           
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {state.serverError ? (
					<Alert severity="error">Incorrect username or password!</Alert>
            ) : (
              ""
            )}
            <Snackbar
            open={state.openSnack}
            message="You have successfully logged in"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          />

            <TextField
              margin="normal"
              required
              fullWidth
              id="Username"
              label="Username"
              name="Username"
              autoFocus
              value={state.usernameValue}
                  onChange={(e) =>
                     dispatch({type: 'catchUsernameChange', 
                               usernameChosen: e.target.value})}
              error={state.serverError ? true : false}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="none"
              value={state.passwordValue}
                  onChange={(e) => 
                    dispatch({type: 'catchPasswordChange',
                              passwordChosen: e.target.value})}
              error={state.serverError ? true : false}
            />
  
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 3 ,backgroundColor:'goldenrod',color:'black'}}
              disabled={state.disabledBtn}
              onClick={scrollToTop}
            >
              Sign In
            </Button>
            <Grid >
              <Grid textAlign={"center"}>
                <Link to="/signup" variant="body2" target='_top'>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5,mb :3 }} />
      </Container>
    </ThemeProvider>
        
    </div>
    </div>
    <Footer />
    </>
  )
}

export default SignIN