import React, { useEffect, useState, useRef, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import './AddProperty.css';
import Axios from "axios";
import { useImmerReducer } from "use-immer";


// Contexts
import StateContext from '../../Contexts/StateContext'

// MUI
import {
	Grid,
	Typography,
	Button,
	TextField,
	FormControlLabel,
	Checkbox,
	Snackbar,
	Alert,
} from "@mui/material";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const areaOptions = [
	{
		value: "",
		label: "",
	},
	{
		value: "Inner Bhubaneswar",
		label: "Inner Bhubaneswar",
	},
	{
		value: "Outer Bhubaneswar",
		label: "Outer Bhubaneswar",
	},
];

const innerBhubaneswarOptions = [
	{
		value: "",
		label: "",
	},
	{
		value: "Chandrasekharpur",
		label: "Chandrasekharpur",
	},
	{
		value: "Damana",
		label: "Damana",
	},
	{
		value: "Infocity",
		label: "Infocity",
	},
	{
		value: "Sailashree Vihar",
		label: "Sailashree Vihar",
	},
	{
		value: "Niladri Vihar",
		label: "Niladri Vihar",
	},
	{
		value: "Rail Vihar",
		label: "Rail Vihar",
	},
	{
		value: "Nalco Nagar",
		label: "Nalco Nagar",
	},
	{
		value: "Gajapati Nagar",
		label: "Gajapati Nagar",
	},
	{
		value: "Vani Vihar",
		label: "Vani Vihar",
	},
	{
		value: "Jaydev Vihar",
		label: "Jaydev Vihar",
	},
	{
		value: "IRC Village",
		label: "IRC Village",
	},
	{
		value: "Patia",
		label: "Patia",
	},
	{
		value: "Baramunda",
		label: "Baramunda",
	},
	{
		value: "A G Colony",
		label: "A G Colony",
	},
	{
		value: "Acharya Vihar",
		label: "Acharya Vihar",
	},
	{
		value: "Ashok Nagar",
		label: "Ashok Nagar",
	},
	{
		value: "Bapuji Nagar",
		label: "Bapuji Nagar",
	},
	{
		value: "Bhauma Nagar",
		label: "Bhauma Nagar",
	},
	{
		value: "Bhoi Nagar",
		label: "Bhoi Nagar",
	},
	{
		value: "Ekamra Vihar",
		label: "Ekamra Vihar",
	},
	{
		value: "Forest Park",
		label: "Forest Park",
	},
	{
		value: "Ganga Nagar",
		label: "Ganga Nagar",
	},
	{
		value: "Keshari Nagar",
		label: "Keshari Nagar",
	},
	{
		value: "Kharavella Nagar",
		label: "Kharavella Nagar",
	},
	{
		value: "Madhusudan Nagar",
		label: "Madhusudan Nagar",
	},

	{
		value: "Master Canteen",
		label: "Master Canteen",
	},
	{
		value: "Nayapalli",
		label: "Nayapalli",
	},
	{
		value: "Saheed Nagar",
		label: "Saheed Nagar",
	},
	{
		value: "Satya Nagar",
		label: "Satya Nagar",
	},
	{
		value: "Surya Nagar",
		label: "Surya Nagar",
	},
	{
		value: "Unit - 1",
		label: "Unit - 1",
	},
	{
		value: "Unit - 2",
		label: "Unit - 2",
	},
	{
		value: "Unit - 3",
		label: "Unit - 3",
	},
	{
		value: "Unit - 4",
		label: "Unit - 4",
	},
	{
		value: "Unit - 5",
		label: "Unit - 5",
	},
	{
		value: "Unit - 6",
		label: "Unit - 6",
	},
	{
		value: "Unit - 7",
		label: "Unit - 7",
	},
	{
		value: "Unit - 8",
		label: "Unit - 8",
	},
	{
		value: "Samantarapur",
		label: "Samantarapur",
	},
	{
		value: "BJB Nagar",
		label: "BJB Nagar",
	},
	{
		value: "Rajarani Colony",
		label: "Rajarani Colony",
	},
	{
		value: "Pandav Nagar",
		label: "Pandav Nagar",
	},
	{
		value: "Brahmeswar Bagh",
		label: "Brahmeswar Bagh",
	},
	{
		value: "Lingaraj Nagar",
		label: "Lingaraj Nagar",
	},
	{
		value: "Gouri Nagar",
		label: "Gouri Nagar",
	},
	{
		value: "Bhimatangi",
		label: "Bhimatangi",
	},
	{
		value: "Kapileswar",
		label: "Kapileswar",
	},
	{
		value: "Old Town",
		label: "Old Town",
	},
	{
		value: "Gopabandhu Nagar",
		label: "Gopabandhu Nagar",
	},
	{
		value: "Delta Colony",
		label: "Delta Colony",
	},
	{
		value: "CRPF",
		label: "CRPF",
	},

	{
		value: "Jagamara",
		label: "Jagamara",
	},
	{
		value: "Khandagiri",
		label: "Khandagiri",
	},
	{
		value: "Gandamunda",
		label: "Gandamunda",
	},
	{
		value: "Pokhariput",
		label: "Pokhariput",
	},

	{
		value: "Palaspali",
		label: "Palaspali",
	},
	{
		value: "Dumduma",
		label: "Dumduma",
	},
	{
		value: "Patrapada",
		label: "Patrapada",
	},
	{
		value: "Tamando",
		label: "Tamando",
	},
	{
		value: "Kalinga Nagar",
		label: "Kalinga Nagar",
	},
	{
		value: "Ghatikia",
		label: "Ghatikia",
	},
	{
		value: "Mancheswar",
		label: "Mancheswar",
	},
	{
		value: "VSS Nagar",
		label: "VSS Nagar",
	},
	{
		value: "Rasulgarh",
		label: "Rasulgarh",
	},
	{
		value: "Palasuni",
		label: "Palasuni",
	},
	{
		value: "Satya Vihar",
		label: "Satya Vihar",
	},
	{
		value: "Jharpada",
		label: "Jharpada",
	},
	{
		value: "Laxmisagar",
		label: "Laxmisagar",
	},
	{
		value: "Chintamanishwar",
		label: "Chintamanishwar",
	},
	{
		value: "Canal Road",
		label: "Canal Road",
	},
	{
		value: "Pandra",
		label: "Pandra",
	},
	{
		value: "Saptasati Temple",
		label: "Saptasati Temple",
	},
	{
		value: "Naharkanta",
		label: "Naharkanta",
	},

	{
		value: "Hanspal",
		label: "Hanspal",
	},
	{
		value: "Balianta",
		label: "Balianta",
	},
];

const outerBhubaneswarOptions = [
	{
		value: "",
		label: "",
	},
	{
		value: "Jatani",
		label: "Jatani",
	},
	{
		value: "Madanpur",
		label: "Madanpur",
	},
	{
		value: "Cuttuk",
		label: "Cuttuk",
	},
	{
		value: "Uttara",
		label: "Uttara",
	},
];

const propertyTypeOptions = [
	{
		value: "",
		label: "",
	},
	{
		value: "1-BHK Apartment",
		label: "1-BHK Apartment",
	},
    {
		value: "2-BHK Apartment",
		label: "2-BHK Apartment",
	},
    {
		value: "3-BHK Apartment",
		label: "3-BHK Apartment",
	},
    {
		value: "4-BHK Apartment",
		label: "4-BHK Apartment",
	},
	{
		value: "House",
		label: "House",
	},
    {
		value: "Duplex",
		label: "Duplex",
	},
    {
		value: "Studio",
		label: "Studio",
	},
	{
		value: "Office",
		label: "Office",
	},
	{
		value: "PG",
		label: "PG",
	},
	{
		value: "Hostel",
		label: "Hostel",
	},
	{
		value: "Land",
		label: "Land",
	},
];

const categoryOptions = [
	{
		value: "",
		label: "",
	},
	{
		value: "Rent",
		label: "Rent",
	},
];

const rentalFrequencyOptions = [
	{
		value: "",
		label: "",
	},
	{
		value: "Month",
		label: "Month",
	},
];


const AddProperty = () => {
    const navigate = useNavigate();
	const GlobalState = useContext(StateContext);

	const initialState = {
		titleValue: "",
		propertyTypeValue: "",
		descriptionValue: "",
		areaValue: "",
		placeValue: "",
        addressValue: "",
		categoryValue: "",
		priceValue: "",
        contactNumberValue: "",
		rentalFrequencyValue: "",
		roomsValue: "",
		furnishedValue: false,
		bachelorValue: false,
		elevatorValue: false,
		girlsValue: false,
		parkingValue: false,
		picture1Value: "",
		picture2Value: "",
		picture3Value: "",
		picture4Value: "",
		picture5Value: "",
		propertySizeValue: "",
		uploadedPictures: [],
		sendRequest: 0,
		openSnack: false,
		disabledBtn: false,
		titleErrors: {
			hasErrors: false,
			errorMessage: "",
		},
		propertyTypeErrors: {
			hasErrors: false,
			errorMessage: "",
		},
		categoryErrors: {
			hasErrors: false,
			errorMessage: "",
		},
		priceErrors: {
			hasErrors: false,
			errorMessage: "",
		},
        contactNumberErrors: {
			hasErrors: false,
			errorMessage: "",
		},
		areaErrors: {
			hasErrors: false,
			errorMessage: "",
		},
		placeErrors: {
			hasErrors: false,
			errorMessage: "",
		},
        addressErrors: {
			hasErrors: false,
			errorMessage: "",
		},
		propertySizeErrors:{
			hasErrors: false,
			errorMessage: "",
		},
		roomsError:{
			hasErrors:false,
			errorMessage: "",
		},
	};

	function ReducerFuction(draft, action) {
		switch (action.type) {
			case "catchTitleChange":
				draft.titleValue = action.titleChosen;
				draft.titleErrors.hasErrors = false;
				draft.titleErrors.errorMessage = "";
				break;

			case "catchPropertyTypeChange":
				draft.propertyTypeValue = action.propertyTypeChosen;
				draft.propertyTypeErrors.hasErrors = false;
				draft.propertyTypeErrors.errorMessage = "";
				break;

			case "catchDescriptionChange":
				draft.descriptionValue = action.descriptionChosen;
				break;

			case "catchAreaChange":
				draft.areaValue = action.areaChosen;
				draft.areaErrors.hasErrors = false;
				draft.areaErrors.errorMessage = "";
				break;

			case "catchPlaceChange":
				draft.placeValue = action.placeChosen;
				draft.placeErrors.hasErrors = false;
				draft.placeErrors.errorMessage = "";
				break;


			case "catchCategoryChange":
				draft.categoryValue = action.categoryChosen;
				draft.categoryErrors.hasErrors = false;
				draft.categoryErrors.errorMessage = "";
				break;

			case "catchPriceChange":
				draft.priceValue = action.priceChosen;
				draft.priceErrors.hasErrors = false;
				draft.priceErrors.errorMessage = "";
				break;

			case "catchContactNumberChange":
				draft.contactNumberValue = action.contactNumberChosen;
				draft.contactNumberErrors.hasErrors = false;
				draft.contactNumberErrors.errorMessage = "";
				break;
			
			case "catchPropertySizeChange":
				draft.propertySizeValue = action.propertySizeChosen;
				draft.propertySizeErrors.hasErrors = false;
				draft.propertySizeErrors.errorMessage = "";
				break;

			case "catchRentalFrequencyChange":
				draft.rentalFrequencyValue = action.rentalFrequencyChosen;
				break;

			case "catchRoomsChange":
				draft.roomsValue = action.roomsChosen;
				break;

			case "catchFurnishedChange":
				draft.furnishedValue = action.furnishedChosen;
				break;

			case "catchBachelorChange":
				draft.bachelorValue = action.bachelorChosen;
				break;

			case "catchElevatorChange":
				draft.elevatorValue = action.elevatorChosen;
				break;

			case "catchGirlsChange":
				draft.girlsValue = action.girlsChosen;
				break;

			case "catchParkingChange":
				draft.parkingValue = action.parkingChosen;
				break;

			case "catchAddressChange":
				draft.addressValue = action.addressChosen;
				break;

			case "catchPicture1Change":
				draft.picture1Value = action.picture1Chosen;
				break;

			case "catchPicture2Change":
				draft.picture2Value = action.picture2Chosen;
				break;

			case "catchPicture3Change":
				draft.picture3Value = action.picture3Chosen;
				break;

			case "catchPicture4Change":
				draft.picture4Value = action.picture4Chosen;
				break;

			case "catchPicture5Change":
				draft.picture5Value = action.picture5Chosen;
				break;

			// case "getMap":
			// 	draft.mapInstance = action.mapData;
			// 	break;

			// case "changeMarkerPosition":
			// 	draft.markerPosition.lat = action.changeLatitude;
			// 	draft.markerPosition.lng = action.changeLongitude;
			// 	draft.latitudeValue = "";
			// 	draft.longitudeValue = "";
			// 	break;

			case "catchUploadedPictures":
				draft.uploadedPictures = action.picturesChosen;
				break;

			

			case "changeSendRequest":
				draft.sendRequest = draft.sendRequest + 1;
				break;


			case "openTheSnack":
				draft.openSnack = true;
				break;

			case "disableTheButton":
				draft.disabledBtn = true;
				break;

			case "allowTheButton":
				draft.disabledBtn = false;
				break;

			case "catchTitleErrors":
				if (action.titleChosen.length === 0) {
					draft.titleErrors.hasErrors = true;
					draft.titleErrors.errorMessage = "This field must not be empty";
				}
				break;

			case "catchPropertyTypeErrors":
				if (action.propertyTypeChosen.length === 0) {
					draft.propertyTypeErrors.hasErrors = true;
					draft.propertyTypeErrors.errorMessage = "This field must not be empty";
				}
				break;

			case "catchCategoryErrors":
				if (action.categoryChosen.length === 0) {
					draft.categoryErrors.hasErrors = true;
					draft.categoryErrors.errorMessage =
						"This field must not be empty";
				}
				break;

			case "catchPriceErrors":
				if (action.priceChosen.length === 0) {
					draft.priceErrors.hasErrors = true;
					draft.priceErrors.errorMessage = "This field must not be empty";
				}
				break;

			case "catchContactNumberErrors":
				if (action.contactNumberChosen.length != 10) {
					draft.contactNumberErrors.hasErrors = true;
					draft.contactNumberErrors.errorMessage = "Please enter a 10 digit phone number";
				}
				break;

			case "catchPropertySizeErrors":
				if (action.propertySizeChosen.length === 0) {
					draft.propertySizeErrors.hasErrors = true;
					draft.propertySizeErrors.errorMessage = "This field must not be empty";
				}
				break;
			

			case "catchAreaErrors":
				if (action.areaChosen.length === 0) {
					draft.areaErrors.hasErrors = true;
					draft.areaErrors.errorMessage = "This field must not be empty";
				}
				break;

			case "catchPlaceErrors":
				if (action.placeChosen.length === 0) {
					draft.placeErrors.hasErrors = true;
					draft.placeErrors.errorMessage = "This field must not be empty";
				}
				break;

			// case "catchRoomsError":
			// 	if(draft.roomsValue < 1){
			// 		draft.roomsError.hasErrors = true;
			// 		draft.roomsError.errorMessage = "No of rooms can't be less than 1"
			// 	}

			case "emptyTitle":
				draft.titleErrors.hasErrors = true;
				draft.titleErrors.errorMessage = "This field must not be empty";
				break;

			case "emptyPropertyType":
				draft.propertyTypeErrors.hasErrors = true;
				draft.propertyTypeErrors.errorMessage = "This field must not be empty";
				break;

			case "emptyCategoryStatus":
				draft.categoryErrors.hasErrors = true;
				draft.categoryErrors.errorMessage =
					"This field must not be empty";
				break;

			case "emptyPrice":
				draft.priceErrors.hasErrors = true;
				draft.priceErrors.errorMessage = "This field must not be empty";
				break;

            case "emptyContactNumber":
                    draft.contactNumberErrors.hasErrors = true;
                    draft.contactNumberErrors.errorMessage = "This field must not be empty";
                    break;

			case "emptyArea":
				draft.areaErrors.hasErrors = true;
				draft.areaErrors.errorMessage = "This field must not be empty";
				break;

			case "emptyplace":
				draft.placeErrors.hasErrors = true;
				draft.placeErrors.errorMessage = "This field must not be empty";
				break;
            case "emptyAddress":
                    draft.addressErrors.hasErrors = true;
                    draft.addressErrors.errorMessage = "This field must not be empty";
                    break;
			case "emptyPropertySize":
					draft.propertySizeErrors.hasErrors = true;
					draft.propertySizeErrors.errorMessage = "This field must not be empty";
					break;
		}
	}

	const [state, dispatch] = useImmerReducer(ReducerFuction, initialState);
  // Catching picture fields
	useEffect(() => {
		if (state.uploadedPictures[0]) {
			dispatch({
				type: "catchPicture1Change",
				picture1Chosen: state.uploadedPictures[0],
			});
		}
	}, [state.uploadedPictures[0]]);

	useEffect(() => {
		if (state.uploadedPictures[1]) {
			dispatch({
				type: "catchPicture2Change",
				picture2Chosen: state.uploadedPictures[1],
			});
		}
	}, [state.uploadedPictures[1]]);

	useEffect(() => {
		if (state.uploadedPictures[2]) {
			dispatch({
				type: "catchPicture3Change",
				picture3Chosen: state.uploadedPictures[2],
			});
		}
	}, [state.uploadedPictures[2]]);

	useEffect(() => {
		if (state.uploadedPictures[3]) {
			dispatch({
				type: "catchPicture4Change",
				picture4Chosen: state.uploadedPictures[3],
			});
		}
	}, [state.uploadedPictures[3]]);

	useEffect(() => {
		if (state.uploadedPictures[4]) {
			dispatch({
				type: "catchPicture5Change",
				picture5Chosen: state.uploadedPictures[4],
			});
		}
	}, [state.uploadedPictures[4]]);


	function FormSubmit(e) {
		e.preventDefault();

		if (
			!state.titleErrors.hasErrors &&
			!state.propertyTypeErrors.hasErrors &&
			!state.categoryErrors.hasErrors &&
			!state.priceErrors.hasErrors &&
            !state.contactNumberErrors.hasErrors &&
			!state.areaErrors.hasErrors &&
			!state.placeErrors.hasErrors &&
			!state.addressErrors.hasErrors &&
			!state.propertySizeErrors.hasErrors
		) {
			dispatch({ type: "changeSendRequest" });
			dispatch({ type: "disableTheButton" });
		} else if (state.titleValue === "") {
			dispatch({ type: "emptyTitle" });
			window.scrollTo(0, 0);
		} else if (state.propertyTypeValue === "") {
			dispatch({ type: "emptyListingType" });
			window.scrollTo(0, 0);
		} else if (state.categoryValue === "") {
			dispatch({ type: "emptyPropertyStatus" });
			window.scrollTo(0, 0);
		} else if (state.priceValue === "") {
			dispatch({ type: "emptyPrice" });
			window.scrollTo(0, 0);
        } else if (state.contactNumberValue === "") {
			dispatch({ type: "emptyContactNumber" });
			window.scrollTo(0, 0);
		} else if (state.areaValue === "") {
			dispatch({ type: "emptyArea" });
			window.scrollTo(0, 0);
		} else if (state.placeValue === "") {
			dispatch({ type: "emptyPlace" });
			window.scrollTo(0, 0);
		} else if (state.addressValue === "") {
            dispatch({ type: "emptyAddress" });
            window.scrollTo(0, 0);
        } else if (state.propertySizeValue === "") {
            dispatch({ type: "emptyPropertySize" });
            window.scrollTo(0, 0);
        }
	}

	useEffect(() => {
		if (state.sendRequest) {
			async function AddProperty() {
				const formData = new FormData();
				formData.append("c_username",GlobalState.userUsername);
				formData.append("c_title", state.titleValue);
				formData.append("c_description", state.descriptionValue);
				formData.append("c_area", state.areaValue);
				formData.append("c_place", state.placeValue);
				formData.append("c_propertyType", state.propertyTypeValue);
				formData.append("c_category", state.categoryValue);
				formData.append("c_price", state.priceValue);
				formData.append("c_rental_frequency", state.rentalFrequencyValue);
				formData.append("c_rooms", state.roomsValue);
                formData.append("c_contact_number", state.contactNumberValue);
				formData.append("c_furnished", state.furnishedValue);
				formData.append("c_only_girls", state.girlsValue);
				formData.append("c_elevator", state.elevatorValue);
				formData.append("c_bachelor", state.bachelorValue);
				formData.append("c_parking", state.parkingValue);
				formData.append("c_address", state.addressValue);
				formData.append("c_picture1", state.picture1Value);
				formData.append("c_picture2", state.picture2Value);
				formData.append("c_picture3", state.picture3Value);
				formData.append("c_picture4", state.picture4Value);
				formData.append("c_picture5", state.picture5Value);
				formData.append("c_property_size", state.propertySizeValue);
				// formData.append("person", GlobalState.userId);

				try {
					const response = await Axios.post(
						"https://ojiservices.online/api/listing_by_costumer/create/",
						formData
					);
					dispatch({ type: "openTheSnack" });
					alert("Congratulations !! you have successfully submitted your property for listing...Our team will contact you for verification shortly...")
				} catch (e) {
					dispatch({ type: "allowTheButton" });
				}
			}
			AddProperty();
		}
	}, [state.sendRequest]);

	function PriceDisplay() {
		if(
			state.categoryValue === "Rent" &&
			state.rentalFrequencyValue === "Month"
		)  {
			return "Price per Month*";
		} 
         else {
			return "Price*";
		}
	}

	

	useEffect(() => {
		if (state.openSnack) {
			setTimeout(() => {
				navigate("/");
			}, 1500);
		}
	}, [state.openSnack]);


	const scrollToTop = () => {
        window.scrollTo(0, 0)
    }



	return (
		<>
		<Header />
		<div className="addp-wrapper" style={{marginTop:"4.5rem"}}>
			<form onSubmit={FormSubmit} style={{
                backgroundColor:'white',
				width: "75%",
				marginLeft: "auto",
				marginRight: "auto",
				marginTop: "3rem",
				border: "2px solid black",
				padding: "5%",
                borderRadius: '8px',
			}}
            className="addp-form">
				<Grid item container justifyContent="center">
					<Typography variant="h4">SUBMIT A PROPERTY FOR RENT</Typography>
				</Grid>

				<Grid item container sx={{ marginTop: "1rem" }}>
					<TextField
						id="c_username"
						label="Username*"
						variant="standard"
						fullWidth
						value={GlobalState.userUsername}
						disabled
					/>
				</Grid>

				<Grid item container sx={{ marginTop: "1rem" }}>
					<TextField
						id="title"
						label="Title"
						variant="standard"
						fullWidth
						required
						value={state.titleValue}
						onChange={(e) =>
							dispatch({
								type: "catchTitleChange",
								titleChosen: e.target.value,
							})
						}
						onBlur={(e) =>
							dispatch({
								type: "catchTitleErrors",
								titleChosen: e.target.value,
							})
						}
						error={state.titleErrors.hasErrors ? true : false}
						helperText={state.titleErrors.errorMessage}
					/>
				</Grid>

				<Grid justifyContent="space-between" className="addp-input">
					<Grid className="addp-input-box">
						<TextField
							id="propertyType"
							label="Property Type"
							variant="standard"
							fullWidth
							required
							value={state.propertyTypeValue}
							onChange={(e) =>
								dispatch({
									type: "catchPropertyTypeChange",
									propertyTypeChosen: e.target.value,
								})
							}
							onBlur={(e) =>
								dispatch({
									type: "catchPropertyTypeErrors",
									propertyTypeChosen: e.target.value,
								})
							}
							error={state.propertyTypeErrors.hasErrors ? true : false}
							helperText={state.propertyTypeErrors.errorMessage}
							select
							SelectProps={{
								native: true,
							}}
						>
							{propertyTypeOptions.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>

					<Grid className="addp-input-box">
						<TextField
							id="category"
							label="Category"
							variant="standard"
							fullWidth
							required
							value={state.categoryValue}
							onChange={(e) =>
								dispatch({
									type: "catchCategoryChange",
									categoryChosen: e.target.value,
								})
							}
							onBlur={(e) =>
								dispatch({
									type: "catchCategoryErrors",
									categoryChosen: e.target.value,
								})
							}
							error={state.categoryErrors.hasErrors ? true : false}
							helperText={state.categoryErrors.errorMessage}
							select
							SelectProps={{
								native: true,
							}}
						>
							{categoryOptions.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>
				</Grid>

				<Grid item container sx={{ marginTop: "1rem" }}>
					<TextField
						id="propertySize"
						label="Property Size(In SquareFt)*"
						inputProps={{min:1}}
						variant="standard"
						fullWidth
						type="Number"
						value={state.propertySizeValue}
						onChange={(e) =>
							dispatch({
								type: "catchPropertySizeChange",
								propertySizeChosen: e.target.value,
							})
						}
						onBlur={(e) =>
							dispatch({
								type: "catchPropertySizeErrors",
								propertySizeChosen: e.target.value,
							})
						}
						error={state.propertySizeErrors.hasErrors ? true : false}
						helperText={state.propertySizeErrors.errorMessage}
					/>
				</Grid>

				<Grid justifyContent="space-between" className="addp-input">
					<Grid  className="addp-input-box">
						<TextField
							id="rentalFrequency"
							label="Rental Frequency"
							variant="standard"
							required
							disabled={state.categoryValue === "Sell" ? true : false}
							fullWidth
							value={state.rentalFrequencyValue}
							onChange={(e) =>
								dispatch({
									type: "catchRentalFrequencyChange",
									rentalFrequencyChosen: e.target.value,
								})
							}
							select
							SelectProps={{
								native: true,
							}}
						>
							{rentalFrequencyOptions.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>

					<Grid className="addp-input-box">
						<TextField
							id="price"
							type="number"
							inputProps={{min:0}}
							label={PriceDisplay()}
							variant="standard"
							fullWidth
							required
							value={state.priceValue}
							onChange={(e) =>
								dispatch({
									type: "catchPriceChange",
									priceChosen: e.target.value,
								})
							}
							onBlur={(e) =>
								dispatch({
									type: "catchPriceErrors",
									priceChosen: e.target.value,
								})
							}
							error={state.priceErrors.hasErrors ? true : false}
							helperText={state.priceErrors.errorMessage}
						/>
					</Grid>
				</Grid>

				<Grid item container sx={{ marginTop: "1rem" }}>
					<TextField
						id="description"
						label="Description"
						variant="outlined"
						multiline
						rows={6}
						fullWidth
						value={state.descriptionValue}
						onChange={(e) =>
							dispatch({
								type: "catchDescriptionChange",
								descriptionChosen: e.target.value,
							})
						}
					/>
				</Grid>

				<Grid justifyContent="space-between" className="addp-input">
					<Grid className="addp-input-box">
						<TextField
							id="rooms"
							label="Rooms"
							type="number"
							variant="standard"
							required
							fullWidth
							disabled={state.propertyTypeValue === "Office" || state.propertyTypeValue === "Land" ? true:false}
							inputProps={{min:1}}
							value={state.roomsValue}
							onChange={(e) =>
								dispatch({
									type: "catchRoomsChange",
									roomsChosen: e.target.value,
								})
							}
							// onBlur={(e) =>
							// 	dispatch({
							// 		type: "catchRoomsErrors",
							// 		roomsChosen: e.target.value,
							// 	})
							// }
							// error={state.roomsError.hasErrors ? true : false}
							// helperText={state.roomsError.errorMessage}
						/>
					</Grid>


                <Grid className='addp-input-box' >
					<TextField
						type="number"
						id="contactNumber"
						label="Contact Number"
						variant="standard"
                        required
						fullWidth
						value={state.contactNumberValue}
						onChange={(e) =>
							dispatch({
								type: "catchContactNumberChange",
								contactNumberChosen: e.target.value,
							})
						}
                        onBlur={(e) =>
                            dispatch({
                                type: "catchContactNumberErrors",
                                contactNumberChosen: e.target.value,
                            })
                        }
                        error={state.contactNumberErrors.hasErrors ? true : false}
                        helperText={state.contactNumberErrors.errorMessage}
                    />
	
				</Grid>
                </Grid>


                <Grid  justifyContent="space-between" className="addp-input">
					<Grid item xs={2} sx={{ marginTop: "1rem" ,}}>
						<FormControlLabel
							control={
								<Checkbox
									checked={state.furnishedValue}
									onChange={(e) =>
										dispatch({
											type: "catchFurnishedChange",
											furnishedChosen: e.target.checked,
										})
									}
								/>
							}
							label="Furnished"
						/>
					</Grid>

					<Grid item xs={2} sx={{ marginTop: "1rem" }}>
						<FormControlLabel
							control={
								<Checkbox
									checked={state.girlsValue}
									onChange={(e) =>
										dispatch({
											type: "catchGirlsChange",
											girlsChosen: e.target.checked,
										})
									}
								/>
							}
							label="Only For Girls"
						/>
					</Grid>

					<Grid item xs={2} sx={{ marginTop: "1rem" }}>
						<FormControlLabel
							control={
								<Checkbox
									checked={state.elevatorValue}
									onChange={(e) =>
										dispatch({
											type: "catchElevatorChange",
											elevatorChosen: e.target.checked,
										})
									}
								/>
							}
							label="Elevator/Lift"
						/>
					</Grid>

					<Grid item xs={2} sx={{ marginTop: "1rem" }}>
						<FormControlLabel
							control={
								<Checkbox
									checked={state.bachelorValue}
									onChange={(e) =>
										dispatch({
											type: "catchBachelorChange",
											bachelorChosen: e.target.checked,
										})
									}
								/>
							}
							label="Bachelor"
						/>
					</Grid>

					<Grid item xs={2} sx={{ marginTop: "1rem" }}>
						<FormControlLabel
							control={
								<Checkbox
									checked={state.parkingValue}
									onChange={(e) =>
										dispatch({
											type: "catchParkingChange",
											parkingChosen: e.target.checked,
										})
									}
								/>
							}
							label="Parking"
						/>
					</Grid>
				</Grid>

				<Grid justifyContent="space-between" className="addp-input">
					<Grid className="addp-input-box">
						<TextField
							id="area"
							label="Area"
							variant="standard"
							fullWidth
							required
							value={state.areaValue}
							onChange={(e) =>
								dispatch({
									type: "catchAreaChange",
									areaChosen: e.target.value,
								})
							}
							onBlur={(e) =>
								dispatch({
									type: "catchAreaErrors",
									areaChosen: e.target.value,
								})
							}
							error={state.areaErrors.hasErrors ? true : false}
							helperText={state.areaErrors.errorMessage}
							select
							SelectProps={{
								native: true,
							}}
						>
							{areaOptions.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>

					<Grid className="addp-input-box">
						<TextField
							id="place"
							label="Place Name"
							variant="standard"
							required
							fullWidth
							value={state.placeValue}
							onChange={(e) =>
								dispatch({
									type: "catchPlaceChange",
									placeChosen: e.target.value,
								})
							}
							onBlur={(e) =>
								dispatch({
									type: "catchPlaceErrors",
									placeChosen: e.target.value,
								})
							}
							error={state.placeErrors.hasErrors ? true : false}
							helperText={state.placeErrors.errorMessage}
							select
							SelectProps={{
								native: true,
							}}
						>
							{state.areaValue === "Inner Bhubaneswar"
								? innerBhubaneswarOptions.map((option) => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
								  ))
								: ""}

							{state.areaValue === "Outer Bhubaneswar"
								? outerBhubaneswarOptions.map((option) => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
								  ))
								: ""}
						</TextField>
					</Grid>
				</Grid>

				

                <Grid item container sx={{ marginTop: "1rem" }}>
					<TextField
						id="address"
						label="Complete Address"
						variant="outlined"
						multiline
						rows={3}
                        required
						fullWidth
						value={state.addressValue}
						onChange={(e) =>
							dispatch({
								type: "catchAddressChange",
								addressChosen: e.target.value,
							})
						}
                        onBlur={(e) =>
                            dispatch({
                                type: "catchAddressErrors",
                                addressChosen: e.target.value,
                            })
                        }
                        error={state.addressErrors.hasErrors ? true : false}
                        helperText={state.addressErrors.errorMessage}
                    />
				
				</Grid>

				<Grid
					item
					container
					xs={6}
					sx={{ marginTop: "2rem", marginLeft: "auto", marginRight: "auto" }}
				>
					<Button
						variant="contained"
						component="label"
						fullWidth
						sx={{
							backgroundColor: "goldenrod",
							color: "black",
							fontSize: "0.8rem",
							border: "1px solid black",
							marginLeft: "1rem",
                            '&:hover':{
                                backgroundColor:"lightblue",
                            }
						}}
					>
						UPLOAD PICTURES (MAX 5)
						<input
							type="file"
							multiple
							accept="image/png, image/jpg, image/jpeg"
							hidden
							required
							onChange={(e) =>
								dispatch({
									type: "catchUploadedPictures",
									picturesChosen: e.target.files,
								})
							}
						/>
					</Button>
				</Grid>
				<p style={{fontSize:"11px",textAlign:'center',marginTop:"1rem"}}>**Select 5 pictures simultaneosly and upload them**</p>
				<Grid item container required>
					<ul required>
						{state.picture1Value ? <li>{state.picture1Value.name}</li> : ""}
						{state.picture2Value ? <li>{state.picture2Value.name}</li> : ""}
						{state.picture3Value ? <li>{state.picture3Value.name}</li> : ""}
						{state.picture4Value ? <li>{state.picture4Value.name}</li> : ""}
						{state.picture5Value ? <li>{state.picture5Value.name}</li> : ""}
					</ul>
				</Grid>

				<Grid
					item
					container
					xs={8}
					sx={{ marginTop: "2rem", marginLeft: "auto", marginRight: "auto" }}
				>
					<Button
					variant="contained"
					fullWidth
					type="submit"
					sx={{
						backgroundColor: "lightblue",
						color: "black",
						fontSize: "1.1rem",
						"&:hover": {
							backgroundColor: "goldenrod",
						},
					}}
					disabled={state.disabledBtn}
					onClick={scrollToTop}
				>
					SUBMIT
				</Button>
				</Grid>
			</form>

			<Snackbar
				open={state.openSnack}
				message="You have successfully added your property!"
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
			/>
		</div>
		<Footer />
		</>
	);
}

export default AddProperty