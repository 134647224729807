import React from 'react';

import { useState , useContext, useEffect}  from 'react'
// Header section

import {Link, useNavigate, useSearchParams} from 'react-router-dom'

//import path
import { nav } from '../data/Data'

//import icons for mobile view
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import CloseIcon from '@mui/icons-material/Close';

//import css file
import './Header.css';

import logo from './oji company.png'

import StateContext from '../../Contexts/StateContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Axios from 'axios';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import DispathContext from '../../Contexts/DispathContext';
import SimpleImageSlider from "react-simple-image-slider";
import PaymentForm from './PaymentForm';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';



const Header = () => {
    const GlobalState = useContext(StateContext);
    const GlobalDispatch = useContext(DispathContext);
    const [navList, setNavList] = useState(false);
    
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    async function Logout() {
		const confirmLogout = window.confirm("Are you sure you want to leave?");
			if(confirmLogout){
                try {
                    const response = await Axios.post(
                        "https://ojiservices.online/api-auth-djoser/token/logout/",
                        GlobalState.userToken,
                        { headers: { Authorization: "Token ".concat(GlobalState.userToken) } }
                    );
                    GlobalDispatch({type : "logout"});     
                } catch (e) {
                    console.log(e.response);
                 }
            }
	}

  return (
    <>
        <header  className='nav-header' >
            <div className="abv">
                <div className='abv-div'>
                <div className="cal">
                    <CallIcon  fontSize='xs' style={{color:"aliceblue",marginTop:".2rem",marginRight:".2rem"}}/>
                    <a href="tel:06743151314" style={{color:"aliceblue"}} > 06743151314</a>
                </div>
                <div className="cal ml">
                    <EmailIcon fontSize='xs' style={{color:"aliceblue",marginTop:".2rem",marginRight:".2rem"}}/>
                    <a href="mailto:info@ojiservices.in" style={{color:"aliceblue",textTransform:'none'}} > info@ojiservices.in </a>{" "}
                </div>
                </div>
                <div > 
                   <PaymentForm />
                </div>
            </div>
            <div className='header-container'>
                <div className='logo-name'>
                    <div className='header-logo'>
                        <img src={logo} alt="LOGO" />
                    </div>
                    <h2 style={{marginTop:"2.5rem"}}>OJI Cusultancy Pvt. Ltd.</h2>
                </div>
                <div className='header-nav'>
                    <ul className={navList ? 'small smallList':"header-list"} >
                        {nav.map((List, index) => (
                            <li key={index} style={{zIndex:"15000"}}>
                                <Link to={List.path} style={{zIndex:"15000"}} target='_top'>    {List.text}
                                </Link>
                            </li>
                        ))} 
                    </ul>
                </div>
                <div >
                {GlobalState.userIsLogged ? (
                  <PopupState variant="popover" popupId="demo-popup-menu" className={navList ? 'small prf':"profile-btn"} >
                  {(popupState) => (
                  <div  className={navList ? 'small prf':"profile-btn"} >
                  <Button variant="contained" {...bindTrigger(popupState)} sx={{backgroundColor:"goldenrod",color:"black",'&:hover':{backgroundColor:"aliceblue"}}}>
                  <AccountCircleIcon /> {GlobalState.userUsername}
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                      <MenuItem onClick={popupState.close}><Link to="/profile" 
                      style={{textDecoration:"none",color:'black'}} target='_top'>My Profile</Link>
                      </MenuItem>
                      <MenuItem  onClick={event => {popupState.close(); Logout(); scrollToTop();}} ><Link to="/" 
                      style={{textDecoration:"none",color:'black'}}>Logout</Link>
                      </MenuItem>
                  </Menu>
              </div>
              )}
              </PopupState>
                
                ) : (
                        <PopupState variant="popover" popupId="demo-popup-menu" className={navList ? 'small':"login-register"}>
                        {(popupState) => (
                        <div  className={navList ? 'small':"login-register"}>
                        <Button variant="contained" {...bindTrigger(popupState)} >
                            Login/Register
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                            <MenuItem onClick={popupState.close}><Link to="/signup" 
                            style={{textDecoration:"none",color:'black'}} target='_top'>Sign Up</Link>
                            </MenuItem>
                            <MenuItem onClick={popupState.close}><Link to="/signin" 
                            style={{textDecoration:"none",color:'black'}} target='_top'>Sign In</Link>
                            </MenuItem>
                        </Menu>
                    </div>
                    )}
                    </PopupState>) }
                </div>   
                   
                <div className='dot-button' onClick={() => setNavList(!navList)}>
                    <button>
                        {navList ? <CloseIcon />:<DensityMediumIcon />}
                    </button>
                </div>
            </div>
        </header>
    </>
  )
}

export default Header;