import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Axios from 'axios'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import {
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import { Snackbar , Alert } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect,useContext } from "react";
import StateContext from "../../Contexts/StateContext";
import { useImmerReducer } from 'use-immer';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const defaultTheme = createTheme();

const Requirement = () => {

    // const { id } = useParams();
    // const [item, setItem] = useState([]);
    const [loading, setLoading] = useState(false);
    const GlobalState = useContext(StateContext);
    
    const navigate = useNavigate();

  const initialstate = {
    propertyTypeValue:'',
    areaValue: '',
    priceValue: '',
    daysValue:'',
    whomValue:'',
    sendRequest: 0,
    openSnack: false,
    disabledBtn: false,
  };

  function ReducerFunction(draft,action){
    switch (action.type){
      case 'catchPropertyTypeChange':
        draft.propertyTypeValue = action.propertyTypeChosen;
        break;

      case 'catchAreaChange':
        draft.areaValue = action.areaChosen;
        break;

      case 'catchPriceChange':
        draft.priceValue = action.priceChosen;
        break;

    case 'catchDaysChange':
        draft.daysValue = action.daysChosen;
        break;

    case 'catchWhomChange':
        draft.whomValue = action.whomChosen;
        break;

           
      case 'changeSendRequest':
        draft.sendRequest = draft.sendRequest + 1;
        break;

      case "openTheSnack":
        draft.openSnack = true;
        break;

      case "disableTheButton":
        draft.disabledBtn = true;
        break;

      case "allowTheButton":
        draft.disabledBtn = false;
        break;
      
    }
  }
  const [state, dispatch] = useImmerReducer(ReducerFunction,initialstate);

  const propertyTypeOptions = [
	{
		value: "",
		label: "",
	},
	{
		value: "1-BHK Apartment",
		label: "1-BHK Apartment",
	},
    {
		value: "2-BHK Apartment",
		label: "2-BHK Apartment",
	},
    {
		value: "3-BHK Apartment",
		label: "3-BHK Apartment",
	},
    {
		value: "4-BHK Apartment",
		label: "4-BHK Apartment",
	},
	{
		value: "House",
		label: "House",
	},
    {
		value: "Duplex",
		label: "Duplex",
	},
    {
		value: "Studio",
		label: "Studio",
	},
	{
		value: "Office",
		label: "Office",
	},
	{
		value: "PG",
		label: "PG",
	},
	{
		value: "Hostel",
		label: "Hostel",
	},
	{
		value: "Land",
		label: "Land",
	},
];

const whomOptions1 =[
    {
        value:"",
        label:"",
    },
    {
        value:"For Family",
        label:"For Family",
    },
    {
        value:"For Bachelor",
        label:"For Bachelor",
    },
    {
        value:"For Girls only",
        label:"For Girls only",
    }
]

const whomOptions2 =[
    {
        value:"",
        label:"",
    },
    {
        value:"For Girls only",
        label:"For Girls only",
    },
    {
        value:"For Boys only",
        label:"For Boys only",
    },
]

const disableOptions=[
    {
        value:"Studio",
    },
    {
        value:"Land",
    },
    {
        value:"Office",
    },
]

    //submit function
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch({ type: "changeSendRequest" });
        dispatch({ type: "disableTheButton" });	
      };

      //send values to backend
      useEffect(() => {
		if (state.sendRequest) {
			async function Requirement() {
				const formData = new FormData();
				formData.append("co_username",GlobalState.userUsername);
				formData.append("co_propertyType", state.propertyTypeValue);
				formData.append("co_area", state.areaValue);
				formData.append("co_price", state.priceValue);
                formData.append("co_no_days ",state.daysValue)
				formData.append("co_for_whom ", state.whomValue);
				

				try {
					const response = await Axios.post(
						"https://ojiservices.online/api/costumer_requiremts/create/",
						formData
					);
					dispatch({ type: "openTheSnack" });
					alert("Thankyou for posting your requirements...Our team will contact you soon...")
				} catch (e) {
					dispatch({ type: "allowTheButton" });
				}
			}
			Requirement();
		}
	}, [state.sendRequest]);
    
      useEffect(() => {
            if (state.openSnack) {
                setTimeout(() => {
                    navigate("/");
                }, 1500);
            }
        }, [state.openSnack]);

        const scrollToTop = () => {
          window.scrollTo(0, 0)
      }

  return (
    <>
    <Header />
    <div className='signup-wrapper' style={{marginTop:"8rem"}}>
      <div className='signup-container'>
        <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          
           <Typography compoenent="h1" variant="h5" sx={{marginTop:"1rem",fontFamily:"monospace",textAlign:"center"}}>
           Post your requirement of desired property
         </Typography>
          

          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                  <TextField
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  value={GlobalState.userUsername}
                  disabled
                  onChange={() => {}}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="propertyType"
                  label="Property Type"
                  value={state.propertyTypeValue}
                //   type='text'
                  onChange={(e) => dispatch({type: 'catchPropertyTypeChange', 
                  propertyTypeChosen: e.target.value})}
                  select
                        SelectProps={{
                            native: true,
                        }}
                   >
                        {propertyTypeOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
              </Grid>
              <Grid item xs={12}>
                  <TextField
                  required
                  fullWidth
                  name="area"
                  label="Area/Place"
                  type="text"
                  id="name"
                  
                  value={state.areaValue}
                  onChange={(e) => 
                    dispatch({type: 'catchAreaChange',
                             areaChosen: e.target.value})}
                />
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="pricerange"
                  label="Price Range"
                  required
                  type="text"
                  inputProps={{min:1}}
                  id="feedback"
                  value={state.priceValue}
                  onChange={(e) => 
                    dispatch({type: 'catchPriceChange', 
                              priceChosen: e.target.value})}
                />
              </Grid>
              <Grid item xs={12}>
              <TextField
                  fullWidth
                  name="days"
                  label="Required for Days/Months/Years"
                  required
                  type="text"
                  inputProps={{min:1}}
                  id="days"
                  value={state.daysValue}
                  onChange={(e) => 
                    dispatch({type: 'catchDaysChange', 
                              daysChosen: e.target.value})}
                />
              </Grid>
              <Grid item xs={12}>
                    <TextField
                  required
                  fullWidth
                  id="whom"
                  label="Required for Whom"
                  disabled={state.propertyTypeValue === "Office" || state.propertyTypeValue === "Studio" || state.propertyTypeValue === "Office" || state.propertyTypeValue === "Land" ? true:false}
                  value={state.whomValue}
                //   type='text'
                  onChange={(e) => dispatch({type: 'catchWhomChange', 
                  whomChosen: e.target.value})}
                  select
                        SelectProps={{
                            native: true,
                        }}
                   >
                        {state.propertyTypeValue === "PG" || state.propertyTypeValue ==="Hostel"
								? whomOptions2.map((option) => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
								  ))
								:(
                                    whomOptions1.map((option) => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
								  )  
                                )) }

                    </TextField>
              </Grid>
            </Grid>
            <Grid>
               <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 5, mb: 2 ,backgroundColor:'goldenrod' , color: 'black',marginBottom:"3rem"}}
              disabled={state.disabledBtn}
              onClick={scrollToTop}
            >
              Submit
            </Button>
            <Snackbar
                open={state.openSnack}
                message="You have successfully submitted the form!"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              />
            </Grid>
           
          </Box>
            </Box>
          </Container>
        </ThemeProvider>
        </div>
        </div>
        <Footer />
        </>
  )
}

export default Requirement