import React,{ useContext } from 'react'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import "./StepOne.css";
import StateContext from '../../Contexts/StateContext';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

export const StepOne = () => {
    const GlobalState = useContext(StateContext);
    const navigate = useNavigate();

    const scrollToTop = () => {
      window.scrollTo(0, 0)
  }
    
  return (
    <>
    <Header />
    <div className="st1" style={{marginTop:"4.5rem"}}>
        <h1 >"Welcome to Add Property Page"</h1>
        <h2 >It's very simple to add a property for <span style={{color:'darkblue'}}>"rent"</span></h2>
        <h3>There are 3 simple steps to follow : </h3>
        <ol>
            <li>First you need to Login to your account and if you have not created still then create it </li>
            <li>Update your profile</li>
            <li>Then fill the property addition form</li>
        </ol>
        <Button onClick={(() => GlobalState.userIsLogged ? navigate('/updateprofile') & scrollToTop() : navigate('/signin') & alert("Please Login to your account to continue...") & scrollToTop())} target="_blank">click here to continue...</Button>
    </div>
    <Footer />
    </>
  )
}
export default StepOne;