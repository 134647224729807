import React from 'react'
import Header from '../Header/Header'
import Footer from './Footer'
import Options from '../home/Options'

const CategoryOptions = () => {
  return (
    <>
        <Header />
        <div style={{backgroundColor:"white",padding:'2%'}}>
        <Options />
        </div>
        <Footer />
    </>
  )
}

export default CategoryOptions