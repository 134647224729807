import React from 'react'
import Header from '../Header/Header'
import Footer from './Footer'
import WhyChoose from '../home/WhyChoose'

const WhyUs = () => {
  return (
    <>
    <Header />
    <div style={{backgroundSize:"cover"}}>
        <WhyChoose />
    </div>
    <Footer />
    </>
  )
}

export default WhyUs