import React from 'react'
import './CompanyContact.css'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

const CompanyContact = () => {
  return (
    <>
    <Header />
        <div className="cont-wrapper" style={{marginTop:'4.5rem'}}>
            <div className="cont-container">
                
                
                <div className="cont-items">
                   <h4>Telephone Number:</h4>
                   <a href="tel:06743151314" target='_blank'> 06743151314</a>
                </div>
                <div className="cont-items">
                   <h4>Mobile Number:</h4>
                   <a href="tel:+919777154888" target='_blank'> 9777154888</a>
                </div>
                <div className="cont-items">
                   <h4>Whatsapp Number:</h4>
                   <a href="https://wa.me/<9777154888>" target='_blank'>9777154888</a>
                </div>
                <div className="cont-items">
                   <h4>Email-ID:</h4>
                   <a href="mailto:info@ojiservices.in" style={{textTransform:"none"}} target='_blank'> info@ojiservices.in</a>
                </div>
                
                <div className="cont-items">
                   <h4>location:</h4>
                   <a href="https://www.google.com/maps/place/Bhubaneswar,+Odisha+751010,+India/@20.289809,85.871851,12z/data=!4m6!3m5!1s0x3a190a739f71f947:0xf72f31e3a5876819!8m2!3d20.2913503!4d85.8716515!16s%2Fm%2F0jwdtbc?hl=en-GB&entry=ttu" target='_blank'> Click Here to Use GPS</a>
                </div>
                <div className="cont-items">
                   <h4>Address:</h4>
                   <p>PLOT NO-101, MAA TARA NIVAS(GROUND FLOOR) RASULGARH, <br/> BHUBANESWAR-751010 ,ODISHA, INDIA</p>
                </div>
            </div>
        </div>
      <Footer />
    </>
  )
}

export default CompanyContact