import React from 'react'
import '../AboutUs/AboutUs.css'
import Header from '../Header/Header'
import Footer from './Footer'
import copyImage from './pexels-pixabay-357514.jpg'

const CopyRight = () => {
  return (
    <>
        <Header />
        <div className="abtus-wrapper" style={{marginTop:"4.5rem"}}>
            <div className="abtus-container">
                <h2>Copyright and Trademark Policy:</h2>
                <div className="abtus-item">
                    
                    <div className="abtus-left">
                    <p>All content included in our Services, such as text, graphics, logos, button icons,
                    images, clips, digital downloads, data compilations, and software, is the property
                    of the Company. It’s Users and its content suppliers are protected by copyright
                    laws. All software used on this Website and Mobile App is the property of the
                    Company.</p>

                    
                    </div>
                    <div className="abtus-right">
                        <img src={copyImage} alt=""  />
                    </div>
                </div>
                
                <div className="abtus-items">
                <p>All other trademarks not owned by Company that appear on this site are the
                    property of their respective owners, who may or may not be affiliated with,
                    connected to, or sponsored by ojiservices.in or its affiliates.</p>
                    
                    <p>
                    If you are a verified rights owner and want to report a listing issue, you may
                    report info@ojiservices.in.
                    </p>
                   
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default CopyRight