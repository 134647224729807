import * as React from 'react';
import { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Snackbar , Alert, FormControlLabel, Checkbox } from "@mui/material";
import Axios from 'axios';
import { useImmerReducer } from 'use-immer';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Link, useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './SignUp.css';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#" style={{color:'inherit'}}>
        Oji Consultancy Pvt. Ltd.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

const SignUp = () => {
  const navigate = useNavigate();

  const initialstate = {
    usernameValue: '',
    emailValue: '',
    passwordValue: '',
    password2Value: '',
    termsconditionsValue:false,
    sendRequest: 0,
    openSnack: false,
    disabledBtn: false,
    usernameErrors: {
			hasErrors: false,
			errorMessage: "",
		},
    emailErrors: {
			hasErrors: false,
			errorMessage: "",
		},
    passwordErrors: {
			hasErrors: false,
			errorMessage: "",
		},
    password2HelperText: "",
		serverMessageUsername: "",
		serverMessageEmail: "",
		serverMessageSimilarPassword: "",
		serverMessageCommonPassword: "",
		serverMessageNumericPassword: "",
  };

  function ReducerFunction(draft,action){
    switch (action.type){
      case 'catchUsernameChange':
        draft.usernameValue = action.usernameChosen;
        draft.usernameErrors.hasErrors = false;
				draft.usernameErrors.errorMessage = "";
				draft.serverMessageUsername = "";
        break;
      case 'catchEmailChange':
        draft.emailValue = action.emailChosen;
        draft.emailErrors.hasErrors = false;
				draft.emailErrors.errorMessage = "";
				draft.serverMessageEmail = "";
        break;
      case 'catchPasswordChange':
        draft.passwordValue = action.passwordChosen;
        draft.passwordErrors.hasErrors = false;
				draft.passwordErrors.errorMessage = "";
				draft.serverMessageSimilarPassword = "";
				draft.serverMessageCommonPassword = "";
				draft.serverMessageNumericPassword = "";
        break;
      case 'catchPassword2Change':
        draft.password2Value = action.password2Chosen;
        if (action.password2Chosen !== draft.passwordValue) {
					draft.password2HelperText = "The passwords must match";
				} else if (action.password2Chosen === draft.passwordValue) {
					draft.password2HelperText = "";
				}
        break;

      case 'catchTermsConditionsChange':
        draft.termsconditionsValue = action.termsconditionsChosen;
        break;
      

      case 'changeSendRequest':
        draft.sendRequest = draft.sendRequest + 1;
        break;

      case "openTheSnack":
        draft.openSnack = true;
        break;

      case "disableTheButton":
        draft.disabledBtn = true;
        break;

      case "allowTheButton":
        draft.disabledBtn = false;
        break;

      case "catchUsernameErrors":
        if (action.usernameChosen.length === 0) {
          draft.usernameErrors.hasErrors = true;
          draft.usernameErrors.errorMessage = "This field must not be empty";
        } else if (action.usernameChosen.length < 5) {
          draft.usernameErrors.hasErrors = true;
          draft.usernameErrors.errorMessage =
            "The username must have at least five characters";
        } else if (!/^([a-zA-Z0-9]+)$/.test(action.usernameChosen)) {
          draft.usernameErrors.hasErrors = true;
          draft.usernameErrors.errorMessage =
            "This field must not have special characters";
        }
        break;

      case "catchEmailErrors":
        if (
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            action.emailChosen
          )
        ) {
          draft.emailErrors.hasErrors = true;
          draft.emailErrors.errorMessage = "Please enter a valid email!";
        }
        break;

      case "catchPasswordErrors":
        if (action.passwordChosen.length < 8) {
          draft.passwordErrors.hasErrors = true;
          draft.passwordErrors.errorMessage =
            "The password must at least have 8 characters!";
        }
        break;

      case "usernameExists":
        draft.serverMessageUsername = "This username already exists!";
        break;

      case "emailExists":
        draft.serverMessageEmail = "This email already exists!";
        break;

      case "similarPassword":
        draft.serverMessageSimilarPassword =
          "The password is too similar to the username!";
        break;

      case "commonPassword":
        draft.serverMessageCommonPassword = "The password is too common!";
        break;

      case "numericPassword":
        draft.serverMessageNumericPassword =
          "The password must not only contain numbers!";
        break;
    }
  }
  const [state, dispatch] = useImmerReducer(ReducerFunction,initialstate);




  //frontend 
    const handleSubmit = (event) => {
        event.preventDefault();
       
		if (
			!state.usernameErrors.hasErrors &&
			!state.emailErrors.hasErrors &&
			!state.passwordErrors.hasErrors &&
			state.password2HelperText === ""
		) {
			dispatch({ type: "changeSendRequest" });
			dispatch({ type: "disableTheButton" });
		}
	
      };

  //backend
 
  useEffect(() => {
    if(state.sendRequest){
      const source = Axios.CancelToken.source();
      async function Register() {
        try{
          const response = await Axios.post(
            "https://ojiservices.online/api-auth-djoser/users/",
            {
              username: state.usernameValue,
              email: state.emailValue,
              password: state.passwordValue,
              re_password: state.password2Value,
              termsconditions: state.termsconditionsValue,
            },
            { 
              CancelToken: source.token ,
            });
            dispatch({ type: "openTheSnack" });
            alert("Congratulations !! Now you are redirected to the SingIn page ")
        }catch (error) {
          dispatch({ type: "allowTheButton" });

					if (error.response.data.username) {
						dispatch({ type: "usernameExists" });
					} else if (error.response.data.email) {
						dispatch({ type: "emailExists" });
					} else if (
						error.response.data.password[0] ===
						"The password is too similar to the username."
					) {
						dispatch({ type: "similarPassword" });
					} else if (
						error.response.data.password[0] === "This password is too common."
					) {
						dispatch({ type: "commonPassword" });
					} else if (
						error.response.data.password[0] ===
						"This password is entirely numeric."
					) {
						dispatch({ type: "numericPassword" });
					}
				}
        
      };
      Register();
      return () => {
        source.cancel();
      };
    }
  },[state.sendRequest]);

  useEffect(() => {
		if (state.openSnack) {
			setTimeout(() => {
				navigate("/signin");
			}, 1500);
		}
	}, [state.openSnack]);


  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <>
    <Header />
    <div className='signup-wrapper' style={{marginTop:"7rem"}}>
      <div className='signup-container'>
        <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          

          <Box component="form"  onSubmit={handleSubmit} sx={{ mt: 3 }}>
          {state.serverMessageUsername ? (
					<Alert severity="error">{state.serverMessageUsername}</Alert>
				) : (
					""
				)}

				{state.serverMessageEmail ? (
					<Alert severity="error">{state.serverMessageEmail}</Alert>
				) : (
					""
				)}

				{state.serverMessageSimilarPassword ? (
					<Alert severity="error">{state.serverMessageSimilarPassword}</Alert>
				) : (
					""
				)}

				{state.serverMessageCommonPassword ? (
					<Alert severity="error">{state.serverMessageCommonPassword}</Alert>
				) : (
					""
				)}

				{state.serverMessageNumericPassword ? (
					<Alert severity="error">{state.serverMessageNumericPassword}</Alert>
				) : (
					""
				)}
        <Snackbar
              open={state.openSnack}
              message="You have successfully created an account!"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="Username"
                  required
                  fullWidth
                  id="Userame"
                  label="Username"
                  autoFocus
                  value={state.usernameValue}
                  onChange={(e) =>
                     dispatch({type: 'catchUsernameChange', 
                               usernameChosen: e.target.value})}
                  onBlur={(e) =>
                  dispatch({
                    type: "catchUsernameErrors",
                    usernameChosen: e.target.value,
                  })
                }
                error={state.usernameErrors.hasErrors ? true : false}
						    helperText={state.usernameErrors.errorMessage}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={state.emailValue}
                  onChange={(e) => dispatch({type: 'catchEmailChange', emailChosen: e.target.value})}
                  onBlur={(e) =>
                    dispatch({
                      type: "catchEmailErrors",
                      emailChosen: e.target.value,
                    })
                  }
                  error={state.emailErrors.hasErrors ? true : false}
                  helperText={state.emailErrors.errorMessage}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  
                  value={state.passwordValue}
                  onChange={(e) => 
                    dispatch({type: 'catchPasswordChange',
                              passwordChosen: e.target.value})}
                  onBlur={(e) =>
                    dispatch({
                      type: "catchPasswordErrors",
                      passwordChosen: e.target.value,
                    })
                  }
                  error={state.passwordErrors.hasErrors ? true : false}
                  helperText={state.passwordErrors.errorMessage}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password2"
                  label="Confirm Password"
                  type="password"
                  id="password2"
                  
                  value={state.password2Value}
                  onChange={(e) => 
                    dispatch({type: 'catchPassword2Change', 
                              password2Chosen: e.target.value})}
                  helperText={state.password2HelperText}
                />
              </Grid>
              <Grid item xs={12} >
                <FormControlLabel
                  control={<Checkbox value="termsconditons" color="primary" />}
                  label={<span style={{fontSize:"14px"}}>I have read and agree with the <Link to='/terms&conditions' target='_blank'>Terms & Conditions</Link> and 
                  <Link to='/privacypolicy' target='_blank'> Privacy Policy</Link> of Oji Consultancy Private Limited</span>}
                  id="termsconditions"
                  value={state.termsconditionsValue}
                  required
                  
                  // className={hideAsterisk ? 'hide-asterisk' : ''}
                  onChange={(e) => 
                    dispatch({type: 'catchTermsConditionsChange', 
                    termsconditionsChosen: e.target.value})}
                    
                />
                
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 ,backgroundColor:'goldenrod' , color: 'black'}}
              disabled={state.disabledBtn}
              onClick={scrollToTop}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="center">
              <Grid item>
                <Link to='/signin' variant="body2" style={{textAlign:'center' ,fontSize:'14px',}} target='_top'>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
            
          </Box>
        </Box>
        <Copyright sx={{ mt: 1,mb:2 }} />
      </Container>
    </ThemeProvider>
    </div>
    </div>
    <Footer />
    </>
  )
}

export default SignUp