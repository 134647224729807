import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Axios from 'axios'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Snackbar , Alert } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import { useImmerReducer } from 'use-immer';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const defaultTheme = createTheme();

const ContactUs = () => {

    const { id } = useParams();
    const [item, setItem] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

  const initialstate = {
    nameValue: '',
    emailValue: '',
    phoneValue: '',
    commentValue: '',
    sendRequest: 0,
    openSnack: false,
    disabledBtn: false,
    emailErrors: {
			hasErrors: false,
			errorMessage: "",
		},
    phoneErrors: {
			hasErrors: false,
			errorMessage: "",
		},
  };

  function ReducerFunction(draft,action){
    switch (action.type){
      case 'catchNameChange':
        draft.nameValue = action.nameChosen;
        break;
      case 'catchEmailChange':
        draft.emailValue = action.emailChosen;
        draft.emailErrors.hasErrors = false;
		draft.emailErrors.errorMessage = "";
        break;
      case 'catchPhoneChange':
        draft.phoneValue = action.phoneChosen;
        draft.phoneErrors.hasErrors = false;
		draft.phoneErrors.errorMessage = "";
        break;
      case 'catchCommentChange':
        draft.commentValue = action.commentChosen;
        break;
           
      case 'changeSendRequest':
        draft.sendRequest = draft.sendRequest + 1;
        break;

      case "openTheSnack":
        draft.openSnack = true;
        break;

      case "disableTheButton":
        draft.disabledBtn = true;
        break;

      case "allowTheButton":
        draft.disabledBtn = false;
        break;


      case "catchEmailErrors":
        if (
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            action.emailChosen
          )
        ) {
          draft.emailErrors.hasErrors = true;
          draft.emailErrors.errorMessage = "Please enter a valid email!";
        }
        break;

      case "catchPhoneErrors":
        if (action.phoneChosen.length != 10) {
          draft.phoneErrors.hasErrors = true;
          draft.phoneErrors.errorMessage =
            "Enter a valid 10 digit phone number";
        }
        break;
      
    }
  }
  const [state, dispatch] = useImmerReducer(ReducerFunction,initialstate);
   
  //get the property Id
    useEffect(() => {
      const getItem = async () => {
        setLoading(true);
        const response = await fetch(`https://ojiservices.online/api/listing/${id}`);
        setItem(await response.json());
        setLoading(false);
      };
      getItem();
    }, []);

    //submit function
    const handleSubmit = (event) => {
        event.preventDefault();
       
		if (
			!state.emailErrors.hasErrors &&
			!state.phoneErrors.hasErrors 
		) {
			dispatch({ type: "changeSendRequest" });
			dispatch({ type: "disableTheButton" });
		}
	
      };

      //send values to backend
      useEffect(() => {
		if (state.sendRequest) {
			async function ContactUs() {
				const formData = new FormData();
				formData.append("cos_prop_id",item.id);
				formData.append("cos_name", state.nameValue);
				formData.append("cos_email", state.emailValue);
				formData.append("cos_phone", state.phoneValue);
				formData.append("cos_comment", state.commentValue);
				

				try {
					const response = await Axios.post(
						"https://ojiservices.online/api/costumer_details/create/",
						formData
					);
					dispatch({ type: "openTheSnack" });
					alert("Congratulations !! you have successfully submitted your contact details...Our team will contact you very shortly...")
				} catch (e) {
					dispatch({ type: "allowTheButton" });
				}
			}
			ContactUs();
		}
	}, [state.sendRequest]);
    
      useEffect(() => {
            if (state.openSnack) {
                setTimeout(() => {
                    navigate("/");
                }, 1500);
            }
        }, [state.openSnack]);

        const scrollToTop = () => {
          window.scrollTo(0, 0)
      }

  return (
    <>
    <Header />
    <div className='signup-wrapper' style={{marginTop:"8rem"}}>
      <div className='signup-container'>
        <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
         
          <Typography compoenent="h1" variant="h5" sx={{marginTop:"1rem",fontFamily:"monospace",textAlign:"center"}}>
            Contact Us For Enquiry
          </Typography>
          

          <Box component="form" onSubmit={handleSubmit}   sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="cos_prop_id"
                  label="Property Id"
                  value={`${item.id}`}
                  disabled
                  onChange={() => {}}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  type='email'
                  autoComplete="email"
                  onChange={(e) => dispatch({type: 'catchEmailChange', emailChosen: e.target.value})}
                  onBlur={(e) =>
                    dispatch({
                      type: "catchEmailErrors",
                      emailChosen: e.target.value,
                    })
                  }
                  error={state.emailErrors.hasErrors ? true : false}
                  helperText={state.emailErrors.errorMessage}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="name"
                  label="Full Name"
                  type="text"
                  id="name"
                  
                  value={state.nameValue}
                  onChange={(e) => 
                    dispatch({type: 'catchNameChange',
                              nameChosen: e.target.value})}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="phn_number"
                  label="Phone Number"
                  type="number"
                  id="phn_number"
                  
                  value={state.phoneValue}
                  onChange={(e) => 
                    dispatch({type: 'catchPhoneChange', 
                              phoneChosen: e.target.value})}
                onBlur={(e) =>
                    dispatch({
                        type: "catchPhoneErrors",
                        phoneChosen: e.target.value,
                    })
                }
                error={state.phoneErrors.hasErrors ? true : false}
                helperText={state.phoneErrors.errorMessage}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="comment"
                  label="Comment"
                  multiline
                  rows={5}
                  type="text"
                  id="comment"
                  value={state.commentValue}
                  onChange={(e) => 
                    dispatch({type: 'catchCommentChange', 
                              commentChosen: e.target.value})}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 5, mb: 2 ,backgroundColor:'goldenrod' , color: 'black',marginBottom:"3rem"}}
              disabled={state.disabledBtn}
              onClick={scrollToTop}
            >
              Submit
            </Button>
            <Snackbar
				open={state.openSnack}
				message="You have successfully submitted the form!"
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
			/>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
    </div>
    </div>
    <Footer />
    </>
  )
}

export default ContactUs