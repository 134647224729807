import React from 'react'
import { whycard } from '../data/Data';
import './Why.css';
import Whychoose from './jpg_20230625_230222_0000.png'

const WhyChoose = () => {
  return (
    <>
        <div className="why-body">
      <section className='why-choose'>
        <div className="why-left">
          <img src={Whychoose} alt="" style={{boxShadow: "2px 2px 25px -7px #4c4c4c"}}/>
        </div>
    
        <div className='why-right'>
            <div className='why-card-holder'>
                {whycard.map((items,index) =>
                    (<div className='why-img' key={index}>
                        <img src={items.cover} alt='' className='why-img-slide' />
                    </div>
                ))}
            </div>
        </div>
        
      </section> 
      </div> 
    </>
  )
}

export default WhyChoose