import React from 'react'
import '../home/Featured.css'
import { services } from '../data/Data'


const ServicesCard = () => {
  return (
    <>
        <div className="foo-con">
          <div className="fc-content fc-grid">
              {services.map((items,index) =>
                  (<div className='fc-box' key={index}>
                      <img src={items.cover} alt='' />
                      <section >
                        <h4 style={{fontSize:"15px"}}>{items.name}</h4>
                      </section>
                      <a href={items.path} target='_top'>
                        <button className='fc-fc-btn'>Click Here</button>
                      </a>
                  </div>
              ))}
          </div>
        </div>
    </>
  )
}

export default ServicesCard