import React from 'react'
import "../AboutUs/AboutUs.css"
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import cookieImg from './pexels-antoni-shkraba-4348404.jpg'

const Cookies = () => {
  return (
    <>
    <Header />
        <div className="abtus-wrapper" style={{marginTop:"4.5rem"}}>
            <div className="abtus-container">
                <h2>Cookies</h2>
                <div className="abtus-item">
                    
                    <div className="abtus-left">
                    <p>Cookies are small data files that we transfer to your computer. We use &quot;session&quot; cookies to keep you
                        logged in while you use our Services, to better understand how you interact with our Services, and to
                        monitor aggregate usage and web traffic information on our Services. We use &quot;persistent&quot; cookies to
                        recognize you each time you return to our Services. For example, we create a persistent cookie that
                        includes some basic information about you, like your most recent search and whether our
                        representatives responded to a query you sent using ojiservices.in&#39;s Services. We use this
                        persistent cookie to remember your preferences and, if you create an account, to make your user
                        experience consistent after you register.
                    </p>

    
                    </div>
                    <div className="abtus-right">
                        <img src={cookieImg} alt=""  />
                    </div>
                </div>
                
                <div className="abtus-items">
                    <p>
                    Most internet browsers automatically accept cookies, but you can change your settings or use third-
                    party tools to refuse cookies or prompt you before accepting cookies from the websites you visit. You
                    can also use your browser settings or other tools to delete cookies you already have. Please be aware
                    that some parts of our Services may not work for you if you disable cookies.
                    </p>
                    
                </div>
            </div>
        </div>
    <Footer />
    </>
  )
}

export default Cookies