import React from 'react'
import '../home/Featured.css'
import Footer from './Footer'
import { services } from '../data/Data'
import ServicesCard from './ServicesCard'
import Header from '../Header/Header'

const Services = () => {
  return (
    <>
    <Header />
        <section className='featured-bgc'>
            <div className="featured-container">
                <header style={{marginTop:"3rem"}}>
                    <h2>Services Provided By Us</h2>
                    <h3>Contact Us for these Services</h3>
                </header>
                <ServicesCard />  
            </div>
        </section>
    <Footer />
    </>
  )
}

export default Services