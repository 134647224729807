import React, { useEffect , useContext } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { useImmerReducer } from "use-immer";

// Contexts
import StateContext from "../../Contexts/StateContext";

// Assets
import defaultProfilePicture from "./defaultProfilePicture.jpg";

// Components
import ProfileUpdate from "./ProfileUpdate";


// MUI
import {
	Grid,
	Typography,
	CircularProgress,
} from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

function Profile() {
	const navigate = useNavigate();
	const GlobalState = useContext(StateContext);
    

	const initialState = {
		userProfile: {
			fullName: "",
			phoneNumber: "",
			profilePic: "",
			personId: "",
			propertyListings: "",
		},
		dataIsLoading: true,
	};

	function ReducerFuction(draft, action) {
		switch (action.type) {
			case "catchUserProfileInfo":
				draft.userProfile.fullName = action.profileObject.full_name;
				draft.userProfile.phoneNumber = action.profileObject.phone_number;
				draft.userProfile.profilePic = action.profileObject.profile_picture;
				draft.userProfile.propertyListings = action.profileObject.property_id_title;
				draft.userProfile.personId = action.profileObject.person;
				break;
			

			case "loadingDone":
				draft.dataIsLoading = false;
				break;
		}
	}

	const [state, dispatch] = useImmerReducer(ReducerFuction, initialState);


	// request to get profile info
	useEffect(() => {
		async function GetProfileInfo() {
			try {
				const response = await Axios.get(
					`https://ojiservices.online/api/profiles/${GlobalState.userId}/`
				);

				dispatch({
					type: "catchUserProfileInfo",
					profileObject: response.data,
				});
				dispatch({ type: "loadingDone" });
			} catch (e) {}
		}
		GetProfileInfo();
	}, []);

	

		function WelcomeDisplay(){
			if(state.userProfile.fullName === null ||
				state.userProfile.fullName === '' ||
				state.userProfile.phoneNumber === null ||
				state.userProfile.phoneNumber ===''){
					return( <div>
						<Typography
									variant="h6"
									style={{ textAlign: "center", marginTop: "1rem" , color:"inherit",textTransform:"capitalize",opacity:'.7'}}
								>
									Welcome{" "}
									<span style={{ color: "green", fontWeight: "bolder" }}>
										{GlobalState.userUsername}
									</span>{" "}
									, please submit this form below to update your profile.
						</Typography>
						</div>)
				}
				else{
					return(
						<Grid container style={{display:"flex",flexDirection:"column",justifyContent:"center" ,alignItems:"center"}}>
							<Grid item>
								<img style={{height:"10rem",widht:"10rem", boxShadow:"rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"}} src={state.userProfile.profilePic ? state.userProfile.profilePic : defaultProfilePicture} />
							</Grid>
							<Grid style={{display:"flex", flexDirection:"column",justifyContent:'center',alignItems:"center"}}>
								<Grid >
								<Typography
										variant="h6"
										style={{  marginTop: "1rem" , color:"inherit",textTransform:"capitalize",opacity:'.7'}}
									>
										Welcome{" "}
										<span style={{ color: "green", fontWeight: "bolder" }}>
											{GlobalState.userUsername}
										</span>
								</Typography>
								</Grid>
								<Grid >
								{state.userProfile.propertyListings ? <Typography 
									style={{marginTop:'1rem'}}>
										Your Listed properties :{" "}<span>{state.userProfile.propertyListings}</span>
								</Typography> : "" }
								</Grid>
							</Grid>
						</Grid>
					)
				}
		}

		if(state.dataIsLoading == true){
			return (
				<Grid
					container
					justifyContent={'center'}
					alignItems={"center"}
					style={{height:"100vh"}}
					>
						<CircularProgress />
					</Grid>
			)
		}

    return (
        <>
        <Header />
        <div style={{backgroundColor:"white" , padding:"2%",marginTop:"4.5rem"}}>
           {WelcomeDisplay()}
        <ProfileUpdate userProfile={state.userProfile}/>
        </div>
        <Footer />

        </>
    )
    
}

export default Profile;