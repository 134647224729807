  import React from 'react'
  import flogo from './oji company.png'
  import './footer.css';
  import {Link} from 'react-router-dom'
  import LocationOnIcon from '@mui/icons-material/LocationOn';
  import FacebookIcon from '@mui/icons-material/Facebook';
  import InstagramIcon from '@mui/icons-material/Instagram';
  import WhatsAppIcon from '@mui/icons-material/WhatsApp';
  import LinkedInIcon from '@mui/icons-material/LinkedIn';
  import { useNavigate } from 'react-router-dom';
  

  export const Footer = () => {
    return (
      <div className="footer">
        <div className="footer section_padding">
            <div className="footer-links">
                <div className="footer-links_div">
                    <div className='f-logo-name'>
                        <img src={flogo} />
                        <h5> Oji Consultancy Pvt. Ltd.</h5>
                    </div>
                    <a href="mailto:support@ojiconsultancy.com" className='f-mail'>
                        Send Email
                    </a>
                    
                        <p style={{ color: "rgb(179, 167, 150)",fontSize:"11px",letterSpacing:"1px"}}><LocationOnIcon className='logo'/>PLOT NO-101, MAA TARA NIVAS(GROUND FLOOR) RASULGARH, <br/> BHUBANESWAR-751010 ,ODISHA, INDIA</p>
                    
                </div>
                <div className="footer-links_div">
                    <h4>Usefull link</h4>
                    <Link to="/rentproperty" target='_top'>
                        <p>Find Propery</p>
                    </Link>
                    <a href="/addpropertystepone" target='_top'>
                        <p>Add Property</p>
                    </a>
                    
                    <Link to="/categories" target='_top'>
                        <p>Catagories</p>
                    </Link>
                    
                </div>
                <div className="footer-links_div" target='_top'>
                    <h4>About</h4>
                    <Link to="/aboutus" target='_top'>
                        <p>About us</p>
                    </Link>
                    <a href="/howitworks" target='_top'>
                        <p>How it works</p>
                    </a>
                    <Link to="/whyus" target='_top'>
                        <p>Why us</p>
                    </Link>
                </div>
                <div className="footer-links_div">
                    <h4>Other's</h4>
                    <Link to="/contact" target='_top'>
                        <p>Contact us</p>
                    </Link>
                    <a href="/costumer_review" target="_top">
                        <p>Feedback/Review</p>
                    </a>
                    <a href="/services" target="_top">
                        <p>Services</p>
                    </a>
                </div>
                <div className="footer-links_div">
                    <h4>Social links</h4>
                    <div className="socialmedia">
                        {/* add link of social icon or img as you wish */}
                        <span><Link to="https://www.facebook.com/profile.php?id=100094226175825&mibextid=ZbWKwL" target='_blank'><FacebookIcon className='f-social'/></Link></span>
                        <span><Link to="https://instagram.com/ojiconsultancy?igshid=ZDc4ODBmNjlmNQ==" target='_blank' ><InstagramIcon className='f-social'/></Link></span>
                        <span><Link to="https://wa.me/<9777154888>" target='_blank'><WhatsAppIcon className='f-social'/></Link></span>
                        <span><Link to="" target='_blank' ><LinkedInIcon className='f-social'/></Link></span>
                    </div>
                </div>
            </div>
            <hr></hr>
            <div className="footer-below">
                <div className="footer-copyright">
                    <Link to="/copyrightpolicy" target='_top'>
                    <p>
                      &copy; Oji Consultancy Pvt. Ltd. All copyright reserved
                    </p>
                    </Link>
                </div>
                <div className="footer-below-links">
                    <Link to="/terms&conditions" target='_top'><div><p>Terms & Conditions</p></div></Link>
                    <Link to="/privacypolicy" target='_top'><div><p>Privacy Policy</p></div></Link>
                    <Link to="/refundpolicy" target='_top'><div><p>Refund Policy</p></div></Link>
                    <Link to="/cookies" target='_top'><div><p>Cookies</p></div></Link>
                   
                </div>
            </div>
            <p className='f-design' style={{textAlign:"start"}}>
                        Designed & Devloped by Miss.Purnima Panda 
            </p>

        </div>
      </div>
    )
  }

  export default Footer;